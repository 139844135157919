import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import Dropzone from "react-dropzone";
import { IoImagesOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import "../styles.scss";
import { Col, Row } from "react-bootstrap";
import AuctionLoading from "./auctionLoading";

function PagesList(props: any) {
  const [isloading, setIsloading] = useState(false);
  const [item, setItem] = useState({}) as any;
  const [updateImage, setUpdateImage] = useState(false);

  const FilePicked = async (value: any) => {
    setIsloading(true);
    if (value?.length) {
      let result = await Promise.all(
        value.map(async (item: any) => {
          return await props.imageUploade(item);
        })
      );
      createObject(result);
      if (result) {
        setIsloading(false);
      }
    } else {
      setIsloading(false);
      message.error("No file Found");
    }
  };
  const createObject = (value: any) => {
    var arro = props?.pages?.length ? props?.pages : [];
    for (let i = 0; i < value?.length; i++) {
      // console.log("========value====createObject=======", value);
      // console.log("========value====location=======", value[i].data.Location);
      // // let obj = {
      // //   index: arro?.length ? Number(arro?.length) + i : i,
      // //   fileUrl: value?.fileUrl ? value?.fileUrl : null,
      // //   file: value[i],
      // //   name: value[i]?.name,
      // //   uploaded: value?.fileUrl ? true : false,
      // // };
      let obj = {
        index: arro?.length ? Number(arro?.length) + i : i,
        file: value[i]?.data.Location,
        key: value[i]?.data.Key,
        uploaded: value[i].data.Key ? true : false,
      };

      arro.push(obj);
    }
    props?.onChagnge(arro);
  };
  const removeItem = (item: any, index: number) => {
    try {
      setIsloading(true);
      let arro = props?.pages;
      arro.splice(index, 1);
      props?.onChagnge(arro);
      setTimeout(() => {
        setIsloading(false);
      }, 50);
    } catch (err) {
      props?.onChagnge(props?.pages);
    }
  };
  return (
    <>
      <label className="newsFormLabels">Upload Images</label>
      <Dropzone onDrop={FilePicked}>
        {({ getRootProps, getInputProps }) => (
          <section {...getRootProps({})}>
            <input {...getInputProps()} />
            <Button
              icon={<UploadOutlined />}
              block
              size="large"
              style={{
                width: "500px",
                color: "gray",
                fontSize: "12px",
                height: "50px",
              }}
            >
              Select Files +
            </Button>
          </section>
        )}
      </Dropzone>
      <div style={{ margin: 30, background: "#000" }} />
      <label className="newsFormLabels">Images</label>
      <div>
        {isloading ? (
          <AuctionLoading />
        ) : props?.pages?.length ? (
          <div style={{ display: "flex", width: "500px" }}>
            <Row style={{ width: "100%" }}>
              {props?.pages?.map((item: any, index: number) => {
                let url;
                if (item?.file) {
                  url = item?.file;
                } else {
                  url = item;
                }

                return (
                  <Col md={4}>
                    <div key={index} className="Auction-pageItem">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                      >
                        <img
                          src={url}
                          style={{
                            width: "100%",
                            height: "100px",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                      <div>
                        Image - <strong>{index + 1}</strong> | &nbsp;{" "}
                        {item?.name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                        }}
                      >
                        <div onClick={() => removeItem(item, index)}>
                          <AiOutlineDelete color="red" size={20} />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <div className="Auction-PagesList">
            <div className="Auction-PagesListBox">
              <IoImagesOutline size={30} />
              <div>No Images Found</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PagesList;
