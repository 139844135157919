import { Area } from "@ant-design/charts";
import { Card } from "antd";

const Charts = (props: any) => {
  const data = [
    { year: "1991", value: 1 },
    { year: "1992", value: 2 },
    { year: "1993", value: 3 },
    { year: "1994", value: 2 },
    { year: "1995", value: 5 },
    { year: "1995", value: 5 },
  ];

  const gradientConfig = {
    type: "linear",
    stops: [
      { offset: 0, color: "#EAF7BD" }, // Start color
      { offset: 1, color: "#FFFFFF" }, // End color
    ],
    global: false, // Indicates local gradient
  };

  const gradientString = `l(90) 0:${gradientConfig.stops[0].color} 1:${gradientConfig.stops[1].color}`;

  const config = {
    data: data,
    xField: "year",
    yField: "value",
    line: {
      style: {
        stroke: "#CAEE45",
      },
    },
    smooth: true,
    style: {
      fill: gradientString,
      fillOpacity: 0.5,
      strokeOpacity: 0.5,
      cursor: "pointer",
      strokeColor: "#CAEE45",
    },
    point: {
      size: 4,
      style: {
        // fill: "#CAEE45",
        fill: "#C9EE45",
        stroke: "#C9EE45",
        lineWidth: 2,
      },
    },
    height: 200,
    tooltip: false,
  };
  return (
    // <Card title={"Analytics"}>
    <Area {...config} />
    // </Card>
  );
};

export default Charts;
