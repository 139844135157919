import { Button, Col, Row } from "antd";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function PageHeader(props: any) {
  const navigate = useNavigate();

  return (
    <div>
      <Row>
        <div className="main-txt">
          <Col md={1} style={{ height: "80%" }}>
            <div className="main-txtBox1" onClick={() => navigate(-1)}>
              <IoMdArrowRoundBack color="white" size={24} />
            </div>
          </Col>
          <Col md={21}>
            <div className="main-txtBox2">{props?.header}</div>
          </Col>
          {props?.navigate ? (
            <Col md={2}>
              <Button
                type="primary"
                className="pageHeader"
                onClick={() => navigate(`/Auth` + props?.navigate)}
              >
                Create
              </Button>
            </Col>
          ) : null}
        </div>
      </Row>
    </div>
  );
}

export default PageHeader;
