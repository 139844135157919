import { useState } from "react";
import { Popover } from "antd";
import { CiSearch } from "react-icons/ci";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import options from "../../assets/data/menu.json";
import profile from "../../assets/images/beauty.jpeg";
import CustomDrawer from "../../components/Drower/drawer";
import Menu from "./menu.json";
import PopoverItem from "./popoverItem";
import Bell from "../../assets/images/notifytrue.png";

const PageHeader = (props: any) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="PageHeaderBox">
      <div className="PageHeader-Box1">
        <div className="naveMenusMOb">
          <div className="naveMenusMOb1">
            <HiOutlineMenuAlt3 size={25} onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>
        <div className="naveMenus">
          <div className="naveMenus_Box1">
            {Menu?.slice(0, 10).map((item: any) => {
              return (
                <div
                  key={item?.id}
                  onClick={() => navigate(item?.navigate)}
                  className={
                    location.pathname === item?.navigate
                      ? "naveBarUser-item active"
                      : "naveBarUser-item"
                  }
                >
                  <div>{item?.title}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="profileBox">
          <div className="searchBoxUser">
            <img
              src={Bell}
              style={{ width: "20px", height: "20px" }}
              onClick={() => navigate(`/usr/Notification`)}
            />
          </div>
          <div className="searchBoxUserProfil">
            <Popover content={<PopoverItem />}>
              <div className="HeaderProfile-box2">
                <img src={profile} />
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <CustomDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        options={options}
      />
    </div>
  );
};

export default PageHeader;
