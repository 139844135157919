import React from "react";
import PageHeader from "../../components/pageHeader";

const Help = () => {
  return (
    <div className="screen-box">
      <div className="main-box">
        <PageHeader header={`Help`} />
      </div>
    </div>
  );
};

export default Help;
