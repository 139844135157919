import { useEffect, useState } from "react";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import "./styles.scss";
import { IoArrowBack } from "react-icons/io5";
import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import { IoMdArrowForward } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";

function DetailedNotification() {
  const params = useParams();
  const Navigate = useNavigate();
  const [notify, setNotify] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getNotification(params?.id);
    updateNotify(params?.id);
  }, []);

  const getNotification = async (id: any) => {
    try {
      let url = API.NOTIFICATION_BY_ID + `/${id}`;

      let res: any = await GET(url, null);
      if (res?.status) {
        setNotify(res?.data?.[0]);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const updateNotify = async (id: any) => {
    try {
      let url = API.UPDATE_NOTIFICATION;

      let obj = {
        id: Number(id),
        Read: true,
      };

      let res: any = await POST(url, obj);
      if (res?.status) {
      }
    } catch (error) {}
  };
  const formattedDate = moment(notify?.createdAt).format("ddd, MMM D, h:mm A");
  const relativeTime = moment(notify?.createdAt).fromNow();
  return (
    <div className="NotificationDetailBox">
      <PageHeader />
      <div className="NotificationDetailBox1">
        <IoArrowBack size={25} onClick={() => Navigate(-1)} />
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Row>
            <Col>
              <br />
              <div className="NotificationDetailBox2">
                <img src={notify?.aucton_details?.images?.[0]} />
                <div className="NotificationDetailBox3">
                  <h5>{notify?.Heading}</h5>

                  <h6>{notify?.Body}</h6>
                </div>
                <div className="NotificationDetailBox4">
                  {formattedDate}&nbsp;
                  {`(${relativeTime})`}
                </div>
              </div>
              <hr />
              <br />
              <div className="NotificationDetailBox5">
                <h5>{notify?.Heading}</h5>
                <h6>{notify?.Body}</h6>
                <div className="NotificationDetailBox6">
                  <img src={notify?.aucton_details?.images?.[0]} />
                  <div className="NotificationDetailBox7">
                    <h5>{notify?.aucton_details?.name}</h5>
                    <h6>{notify?.aucton_details?.description}</h6>
                    <h6>{notify?.aucton_details?.location}</h6>
                    <h4>{`$${notify?.aucton_details?.price}        INR`}</h4>
                    <h3
                      onClick={() =>
                        Navigate(
                          `/details/${Number(notify?.aucton_details?.id)}`
                        )
                      }
                    >
                      Bid Now
                      <IoMdArrowForward />
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

export default DetailedNotification;
