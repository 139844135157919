import React, { useEffect, useState } from "react";
import DataTable from "./components/dataTable";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { Button, Card, Input, message } from "antd";
import PageHeader from "../route/pageHeader";
import Loadingbox from "../../components/loadingBox";
import { API } from "../../config/api";
import { Row, Col, Container } from "react-bootstrap";
import { BsArrowLeftCircle } from "react-icons/bs";
import CategoryNewForm from "./components/newForm";
import "./styles.scss";
import { DELETE, POST } from "../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
function Category() {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [newForm, SetNewForm] = useState(false);
  const [edit, SetEdit] = useState(false);
  const [data, setData] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>();

  useEffect(() => {
    loadData(page, take, "", "");
  }, []);

  const loadData = async (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.LIST_CATEGORY;

      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };
      let response: any = await POST(url, obj);
      if (response) {
        setData(response.data);
        setMeta(response.data.meta);
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
    }
  };

  const deleteCategory = async (val: any) => {
    try {
      let url = API.DELETE_CATEGORY + `/${val}`;
      let response: any = await DELETE(url);
      if (response) {
        message.success("successfully deleted");
        loadData(page, take, "", "");
      }
    } catch (error) {}
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
    loadData(page, take, "", "");
  };
  const onEdit = (val: any) => {
    SetEdit(true);
    setEditData(val);
  };

  const onSearch = (searchText: any) => {
    setTimeout(() => {
      loadData(page, take, searchText, "");
    }, 500);
  };
  return (
    <div className="Category_box">
      <PageHeader title={"Categories"} bredcume={"Categories"}>
        <div>
          <Input
            placeholder="Search Ctegory..."
            onChange={(e: any) => onSearch(e.target.value)}
            allowClear
            suffix={<CiSearch />}
            style={{ width: 200 }}
          />
        </div>

        <div>
          <Button type="primary" onClick={() => SetNewForm(true)}>
            Create Category +{" "}
          </Button>
        </div>
      </PageHeader>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <>
          <Container>
            <Card style={{ width: "100%" }}>
              <DataTable
                meta={meta}
                data={data}
                onDelete={(val: any) => deleteCategory(val)}
                onPageChange={(p: any, t: any) => onPageChange(p, t)}
                onEdit={(val: any) => onEdit(val)}
              />
            </Card>
          </Container>
        </>
      )}
      {newForm ? (
        <CategoryNewForm
          refresh={() => loadData(page, take, "", "")}
          visible={newForm}
          close={() => SetNewForm(false)}
        />
      ) : null}
      {edit ? (
        <CategoryNewForm
          data={editData}
          refresh={() => loadData(page, take, "", "")}
          visible={edit}
          close={() => SetEdit(false)}
        />
      ) : null}
    </div>
  );
}

export default Category;
