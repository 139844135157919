import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Upload,
  UploadProps,
  message,
  Modal,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles.scss";
import { FiUploadCloud } from "react-icons/fi";
import { API } from "../../../config/api";
import { BsArrowLeftCircle } from "react-icons/bs";
import "../styles.scss";
import { Container } from "react-bootstrap";
import PageHeader from "../../route/pageHeader";
import { POST } from "../../../utils/apiCalls";

function CategoryNewForm(props: any) {
  const { data: propsData, visible, close } = props;
  const data = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>(
    propsData?.image ? propsData?.image : ""
  );

  const onFinish = async (val: any) => {
    try {
      let CreateUrl = API.CREATE_CATEGORY;
      let UpdatUlrl = API.UPDATE_CATEGORY;
      let obj = {
        category: val?.category || "",
        description: val?.description || "",
        featured: val?.featured || true,
        image: imageUrl,
      };
      let UpdateObj = {
        category: val?.category || "",
        description: val?.description || "",
        featured: val?.featured || true,
        id: Number(propsData?.id),
        image: imageUrl,
      };

      let response: any = await POST(
        propsData?.id ? UpdatUlrl : CreateUrl,
        propsData?.id ? UpdateObj : obj
      );
      if (response) {
        message.success("successfull");
        props.refresh();
        props.close();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const uploadeImage = async (val: any) => {
    let file = val?.file?.originFileObj;

    try {
      let data = new FormData();
      data.append("file", file);
      let config = {
        method: "post",
        url: API.BASE_URL + API.COMPRESS_IMAGE,
        data: data,
      };
      let response = await axios.request(config);
      if (response) {
        setImageUrl(response.data?.Location);
        message.success("Uploaded Image Successfully");
        return response;
      }
    } catch (error) {
      message.error("Failed to upload image. Please try again");
      console.log(error);
    }
  };

  const propss: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    async onChange(info) {
      setIsLoading(true);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file);
        const result: any = await uploadeImage(info);
        if (result) {
          setIsLoading(false);
        } else {
          message.error("Oops!,Somthing went wrong");
          setIsLoading(false);
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Modal
      visible={visible}
      onCancel={close}
      width={800}
      footer={false}
      centered
    >
      <PageHeader
        title={propsData?.id ? `Update Category` : `Create Category`}
        bredcume={"Category"}
      />
      <br />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          category: propsData?.category,
          description: propsData?.description,
        }}
      >
        <Container>
          <Row gutter={[20, 20]}>
            <Col md={12}>
              <label className="newsFormLabels">Category</label>
              <Form.Item name={"category"}>
                <TextArea style={{ width: 800 }} />
              </Form.Item>
              <label className="newsFormLabels">Image</label>
              <Form.Item name={"image"}>
                <Upload {...propss}>
                  <div className="imageBox1">
                    {imageUrl ? (
                      <>
                        {isLoading ? (
                          <div>Loading . . .</div>
                        ) : (
                          <img src={imageUrl} className="imageBox2" />
                        )}
                      </>
                    ) : (
                      <>
                        {isLoading ? (
                          <div>Loading . . .</div>
                        ) : (
                          <>
                            <FiUploadCloud size={30} />
                            <div>Upload Image</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Upload>
              </Form.Item>
            </Col>
            <Col md={12}>
              <label className="newsFormLabels">Discription</label>
              <Form.Item name={"description"}>
                <TextArea typeof="number" />
              </Form.Item>
            </Col>
          </Row>

          <hr />
          <Row gutter={[20, 20]}>
            <Col md={12}></Col>
            <Col md={12}>
              <Button
                className="SubmitButton"
                type="default"
                htmlType="submit"
                style={{ height: "50px", width: "100%" }}
              >
                {propsData?.id ? `Update` : `Submit`}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  );
}

export default CategoryNewForm;
