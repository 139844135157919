import React, { useEffect, useState } from "react";
import DataTable from "./components/dataTable";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { Button, Card, DatePicker, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import Loadingbox from "../../components/loadingBox";
import { API } from "../../config/api";
import { Container } from "react-bootstrap";
import PageHeader from "../route/pageHeader";
import { DELETE, POST } from "../../utils/apiCalls";

const Auction = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>();

  useEffect(() => {
    loadData(page, take, "", "");
  }, []);

  const loadData = async (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.LIST_AUCTION;

      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };
      let response: any = await POST(url, obj);
      if (response) {
        setData(response?.data);
        setMeta(response.data.meta);
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
      setIsloading(false);
    }
  };

  const deleteAuction = async (val: any) => {
    console.log("=====val=========", val);
    try {
      let url = API.DELETE_AUCTION + `/${val}`;
      let response: any = await DELETE(url);
      if (response?.status) {
        console.log("=====main_response=========", response);
        message.success("successfully deleted");
        await loadData(page, take, "", "");
        DeleteNotification(val);
      }
    } catch (error) {}
  };

  const DeleteNotification = async (id: any) => {
    console.log("=====id=========", id);
    try {
      let url = API.DELETE_NOTIFI_BY_AYCTION + `/${id}`;
      let res: any = await DELETE(url);
      console.log("=====res=========", res);
      if (res?.status) {
        await deleteAuction(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
    loadData(page, take, "", "");
  };

  const onSearch = (searchText: any) => {
    setTimeout(() => {
      loadData(page, take, searchText, "");
    }, 500);
  };

  const onChangeDate = (val: any, s: any) => {
    loadData(page, take, "", s);
  };

  return (
    <div>
      <PageHeader title={"Auctions"} bredcume={"Auctions"}>
        <div>
          <Input
            placeholder="Search Actions..."
            onChange={(e: any) => onSearch(e.target.value)}
            allowClear
            suffix={<CiSearch />}
            style={{ width: 200 }}
          />
        </div>
        <div>
          <DatePicker
            allowClear
            placeholder="Search By EndDate.."
            style={{ width: 200 }}
            onChange={onChangeDate}
          />
        </div>
        <div>
          <Button type="primary" onClick={() => navigate(`/Auth/auction/Form`)}>
            Create Auction +{" "}
          </Button>
        </div>
      </PageHeader>
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <Card>
            <DataTable
              meta={meta}
              data={data}
              onDelete={(val: any) => DeleteNotification(val)}
              onPageChange={(p: any, t: any) => onPageChange(p, t)}
            />
          </Card>
        )}
      </Container>
    </div>
  );
};

export default Auction;
