import { Button, Form, InputNumber, Modal } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../styles.scss";
import TextArea from "antd/es/input/TextArea";
import { API } from "../../../config/api";
import { POST } from "../../../utils/apiCalls";

export default function NotificationModal(props: any) {
  console.log("======props=====", props);
  const navigate = useNavigate();
  const [bidError, setBidError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { data } = props;
  const [form] = Form.useForm();

  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      let url = API.CREATE_NOTIFICATION;
      let body = {
        UserId: Number(props?.data?.UserId),
        auction_ID: Number(props?.data?.itemId),
        Heading: val?.Head,
        Body: val?.Discription,
      };
      let response: any = await POST(url, body);
      if (response?.status) {
      }
    } catch (error) {}
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.close();
      }}
      width={600}
      footer={false}
      centered
    >
      <Row>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            bidprice: data?.price,
          }}
        >
          <Col md={12}>
            <div className="SendNotification">Send Notification</div>
            <hr />
            <label>Heading</label>
            <Form.Item name={"Head"}>
              <TextArea />
            </Form.Item>
            <label>Discription</label>
            <Form.Item name={"Discription"}>
              <TextArea />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="SubmitButton"
                loading={isLoading}
                type="default"
                htmlType="submit"
                style={{ height: "40px", width: "50%" }}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
}
