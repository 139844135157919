import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import SideBar from "./sideBar";
import LayoutHeader from "./layoutHeader";

import Dashboard from "../dashboard";
import Auction from "../auctions";
import Settings from "../settings";
import Help from "../help";
import AuctionNewForm from "../auctions/components/newForm";
import Banner from "../banner";
import Category from "../Category";
import Bids from "../bids";

const Navigation = () => {
  const { Sider, Content } = Layout;
  return (
    <>
      <Layout>
        <Sider
          style={{ backgroundColor: "black", height: "100vh" }}
          width={230}
        >
          <SideBar />
        </Sider>
        <Layout>
          <LayoutHeader />
          <Content
            style={{ background: "#fff", height: "91vh", overflow: "scroll" }}
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/auction" element={<Auction />} />
              <Route path="/auction/Form" element={<AuctionNewForm />} />
              <Route path="/auction/Form/:id" element={<AuctionNewForm />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/help" element={<Help />} />
              <Route path="/Banner" element={<Banner />} />
              <Route path="/Category" element={<Category />} />
              <Route path="/Bids" element={<Bids />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Navigation;
