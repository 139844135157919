import { Button } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineStar } from "react-icons/md";
import "../styles.scss";
import { useNavigate } from "react-router-dom";

function ItemCard(props: any) {
  const navigate = useNavigate();
  return (
    <div className="ItemCard_Box">
      <br />
      <Row>
        {props?.data?.map((item: any) => {
          return (
            <Col md={3}>
              <div
                className="ItemCard_Box1"
                onClick={() => navigate(`/details/${item?.id}`)}
              >
                <img src={item?.images[0]} />
                <div className="ItemCard_Box2">
                  <div className="ItemCard_Box3" style={{ color: "#000" }}>
                    <div>{item?.name}</div>
                  </div>
                  <div className="ItemCard_Box4">{item?.description}</div>
                  <div className="ItemCard_Box5">
                    <div className="ItemCard_Box6">
                      <div className="ItemCard_Box7">{`$${item?.price}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default ItemCard;
