import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";
import Menu from "./menu.json";
import DynamicIcon from "./dymanicIcon";
import Logo from "../../assets/images/newlogo1white.png";
import { useState } from "react";
import LOgoutModel from "../../components/logOutModel";

function SideBar() {
  const navigate = useNavigate();
  let location = useLocation();

  const [logOut, SetLogOut] = useState(false);
  return (
    <div className="sideBar-Box">
      <div className="sideBar-logo">
        <img alt="E-Learn" src={Logo} />
      </div>
      <div>
        {Menu?.slice(0, 8).map((item: any) => {
          return (
            <div
              key={item?.id}
              onClick={() => navigate(item?.navigate)}
              className={
                location.pathname === item?.navigate
                  ? "sideBar-item active"
                  : "sideBar-item"
              }
            >
              <DynamicIcon icon={item?.icon} />
              <div style={{ marginLeft: 20 }} />
              <div>{item?.title}</div>
            </div>
          );
        })}
        <div
          onClick={() => SetLogOut(true)}
          className={"sideBar-item"}
          style={{ color: "red" }}
        >
          <DynamicIcon icon={"FiLogOut"} />
          <div style={{ marginLeft: 20 }} />
          <div>LOGOUT</div>
        </div>
      </div>
      {logOut ? (
        <LOgoutModel visible={logOut} close={() => SetLogOut(false)} />
      ) : null}
    </div>
  );
}

export default SideBar;
