import React from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import about from "../../../assets/images/aboutbackground.jpg";
import arrow from "../../../assets/images/newarr.svg";

function About() {
  return (
    <div className="AboutContaine">
      <Container>
        <Row>
          <Col md={6} xs={12} className="order-2 order-sm-1">
            <div className="About_Containerviewbox2">About Us</div>
            <div className="AboutBox1">
              Welcome to Auction, your premier destination for online auctions
              where the excitement of bidding meets the convenience of digital
              accessibility. At Auction , Our mission is to revolutionize the
              auction experience by providing a safe, transparent, and efficient
              marketplace where everyone can participate and discover unique
              items.
              <br />
              <br />
              Founded on the principles of integrity and innovation, Auction
              offers a diverse array of categories, from rare collectibles and
              antiques to the latest electronics and luxury goods. Our platform
              is designed to cater to both seasoned auction enthusiasts and
              newcomers alike, ensuring a seamless and enjoyable experience for
              all users.
            </div>
            <br />
            <div className="AboutBox3">
              <div className="AboutBox4">more</div>
              <img src={arrow} />
            </div>
          </Col>
          <Col md={6} xs={12} className="order-1 order-sm-2">
            <div className="AboutBox2">
              <img src={about} />
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
}

export default About;
