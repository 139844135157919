import { Button, Form, InputNumber, Modal, message } from "antd";
import { InputOTP } from "antd-input-otp";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Verify from "../../assets/images/passsssword.svg";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import "./styles.scss";

export default function BidModal(props: any) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [Mobverification, setMobVerification] = useState(true);
  const [balanceError, setBalanceError] = useState(false);
  const [bidError, setBidError] = useState("");
  const [wallet, setWallet] = useState<any>();
  const [otp, setOTP] = useState(false);
  const [otpData, setOtpData] = useState<any>();
  const [otpValue, setOtpValue] = useState<any>();
  const { data, userId } = props;
  const User = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();

  useEffect(() => {
    loadWallet();
  }, []);

  const loadWallet = async () => {
    let url = API.GET_BY_USERWALLET;
    let obj = {
      id: Number(User.id),
    };

    try {
      const response: any = await POST(url, obj);
      if (response) {
        setWallet(response);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (val: any) => {
    if (Number(val?.bidprice) <= Number(wallet?.data?.[0]?.wallet_Amount)) {
      setIsLoading(true);

      let obj = {
        name: data?.name,
        itemId: Number(data?.id),
        UserId: Number(User?.id),
        address: User.address,
        bidPrice: val?.bidprice ? Number(val?.bidprice) : null,
        email: User.email,
        phoneNo: User.phoneNo,
      };
      try {
        const url = API.CREATE_BID;
        const response: any = await POST(url, obj);
        if (response?.status === true) {
          await UpdateAuction(val);
          message.success("bid successfully");
          await UpdateWallet(val?.bidprice);
          props.close();
          setBalanceError(false);
          props.refresh();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("bid failed");
          setBalanceError(false);
        }
      } catch (err) {
        console.log(err);
        message.error("Oops, somthing went wrong");
        setIsLoading(false);
      }
    } else {
      setBalanceError(true);
    }
  };
  const UpdateAuction = async (value: any) => {
    let url = API.UPDATE_AUCTION;
    let obj = {
      id: data?.id,
      price: value?.bidprice,
    };
    try {
      const response: any = await POST(url, obj);
      if (response) {
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onchangePrice = (val: any) => {
    if (isNaN(val)) {
      setBidError("Please enter a valid number");
      return;
    } else if (val < data?.price) {
      setBidError(`allowed minimum bid price $${data?.price}`);
    } else {
      setBidError("");
      setBalanceError(false);
    }
  };

  const onSendOTP = async () => {
    try {
      let url = API.REQUEST_OTP;
      let obj = {
        phoneNumber: `+${User.phoneNo}`,
      };
      let res: any = await POST(url, obj);
      if (res?.reqstatus) {
        setOTP(true);
        setOtpData(res);
        setOtpValue("");
      }
    } catch (error) {
      console.log("==error==", error);
    }
  };

  const otpConfirm = async (val: any) => {
    let stringOTP = val?.join("");
    try {
      let url = API.VERIFY_SMS_OTP;
      let obj = {
        token: otpData?.token,
        otp: stringOTP,
        phoneNumber: otpData?.data?.phoneNo,
        user_id: otpData?.data?.id,
      };
      const response: any = await POST(url, obj);
      if (response?.data?.verifystatus) {
        setMobVerification(false);
      }
    } catch (error) {}
  };

  const UpdateWallet = async (val: any) => {
    let obj = {
      amount: Number(val),
      date: new Date().toISOString(),
      transactionId: "withdraw_from_wallet",
      type: "withdraw",
    };
    let body = {
      id: Number(wallet?.data?.[0]?.id),
      wallet_Amount: Number(wallet?.data?.[0]?.wallet_Amount) - Number(val),
      payment_details: obj,
    };
    let url = API.UPDATE_WALLET;
    try {
      const response: any = await POST(url, body);
      if (response) {
        isertTransaction(response);
      }
    } catch (error) {
      console.log("=======Error=====", error);
    }
  };
  const isertTransaction = async (val: any) => {
    let body = {
      mode: val?.data?.type || val?.data?.payment_details?.type,
      wallet_ID: Number(val?.data?.id),
      user_ID: Number(val?.data?.user_ID),
      transaction_details: val?.data?.payment_details,
    };
    let url = API.CREATE_TRANSACTION;
    try {
      const response: any = await POST(url, body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.close();
        setBalanceError(false);
      }}
      width={400}
      footer={false}
      centered
    >
      <Row>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            bidprice: data?.price,
          }}
        >
          <Col md={12}>
            {Mobverification ? (
              <>
                <div className="BidModalBox1">
                  <img src={Verify} />
                  <div className="BidModalBox2">Verification Method</div>
                  <div className="BidModalBox3">The code will be send to </div>
                  <div className="BidModalBox4">{`+${User.phoneNo}`}</div>

                  {otp ? (
                    <>
                      <br />
                      <InputOTP
                        value={otpValue}
                        onChange={setOtpValue}
                        autoSubmit={otpConfirm}
                        inputType="numeric"
                      />
                      <br />
                      <div className="forgot-div">
                        <b onClick={onSendOTP}>resend OTP</b>
                      </div>
                    </>
                  ) : (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        className="BidModalBox5"
                        style={{ backgroundColor: "#57a721", color: "#fff" }}
                        onClick={onSendOTP}
                      >
                        <FaWhatsapp color="#fff" size={25} />
                        WhatsApp
                      </Button>

                      <div
                        id="recaptcha-container"
                        className="justify-center flex"
                      ></div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <label className="Bid_Price"> Bid Price</label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter price",
                    },
                  ]}
                  validateStatus={bidError ? "error" : ""}
                  help={bidError}
                  name={"bidprice"}
                >
                  <InputNumber
                    controls={false}
                    className="custom-input-number"
                    size="large"
                    onChange={(val: any) => onchangePrice(val)}
                  />
                </Form.Item>
                {balanceError ? (
                  <div className="BidModalBox6">
                    <div className="BidModalBox7">
                      <FiInfo color="#402a1c" size={25} />
                    </div>
                    <div className="BidModalBox8">
                      <div className="BidModalBox8TXT1">
                        Insufficient wallet balance
                      </div>
                      <div className="BidModalBox8TXT2">
                        Your wallet does not have enough
                        <br />
                        balance. Check your balance and try again
                      </div>
                      <div className="BidModalBox9">
                        <Button onClick={() => navigate("/usr/transactions")}>
                          Go to Wallet
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="UpdatePassword-btn-Box">
                  <Button
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "20px",
                    }}
                    htmlType="submit"
                    type="primary"
                    loading={isLoading}
                    disabled={balanceError}
                  >
                    submit
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Form>
      </Row>
    </Modal>
  );
}
