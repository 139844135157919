import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

function AuctionBoxes(props: any) {
  const navigate = useNavigate();
  return (
    <div>
      <Row>
        {props?.data?.length
          ? props?.data?.map((item: any) => {
              return (
                <Col lg={4} sm={4} xs={12} className="auction_Box1COL">
                  <div
                    className="auction_Box1"
                    onClick={() => navigate(`/details/${item?.id}`)}
                  >
                    <img
                      style={{ objectFit: "cover" }}
                      className="auction_Box1IMG"
                      src={item?.images[0]}
                    />
                    <div className="auction_Box2">
                      <div className="auction_Box2Header">
                        <div style={{ width: "70%", color: "#000" }}>
                          {item?.name}
                        </div>
                      </div>
                      <div className="auction_Box2title">
                        {item?.description}
                      </div>
                      <div className="auction_Box2Pricing">
                        <div
                          className="auction_Box2Header"
                          style={{ color: "#9f5f2d" }}
                        >
                          {`$${item?.price}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })
          : null}
      </Row>
    </div>
  );
}

export default AuctionBoxes;
