import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import "./styles.scss";
import Contact from "./components/contact";

const Contactus = () => {
  return (
    <div className="Contactus_Box1">
      <Container>
        <Header />
        <Contact />
      </Container>
    </div>
  );
};

export default Contactus;
