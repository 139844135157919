import React from "react";
import { LuUserCircle } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slice/userSlice";

const PopoverItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="Profile-popover">
      <div className="Popover-item1">
        <LuUserCircle size={28} />
        <div className="Popover-txt1">John Honai</div>
      </div>

      <div
        className="Popover-item3"
        onClick={() => {
          navigate(`/`);
          dispatch(logout());
        }}
      >
        Logout
      </div>
    </div>
  );
};

export default PopoverItem;
