import { Pagination, Space, Table, Tag, Tooltip, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import "dayjs/locale/zh-cn";
import moment from "moment";
import { useState } from "react";
import { BsSend } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function DataTable(props: any) {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [Notifications, contextHolder] = notification.useNotification();

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys?.length) {
      props.allowRefund();
      const selectedData = props.data.filter((item: any) => {
        return newSelectedRowKeys.includes(item?.id);
      });
      props.selected(selectedData);
    } else {
      props.noRefund();
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const dataSource = props?.data?.map((item: any) => {
    return { ...item, key: item.id };
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "1",
      width: 30,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "image",
      dataIndex: "image",
      key: "2",
      width: 100,
      render: (text, record: any) => {
        return (
          <>
            {record?.auction_details?.images ? (
              <img
                className="AuctionImage"
                src={record?.auction_details?.images[0]}
              />
            ) : (
              <div className="AuctionImageNull">no media</div>
            )}
          </>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "3",
      width: 100,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {record?.name}
          </div>
        );
      },
    },
    {
      title: "Bid owner",
      dataIndex: "name",
      key: "5",
      width: 100,
      render: (text: any, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {record?.user_details?.name}
          </div>
        );
      },
    },
    {
      title: "Bid Price",
      dataIndex: "Bid price",
      key: "6",
      width: 100,
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            <Tag style={{ fontSize: "12px" }}>{`$${record?.bidPrice}`}</Tag>
          </Space>
        );
      },
    },
    {
      title: "Lot Number",
      dataIndex: "lot",
      key: "7",
      width: 100,
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            <Tag style={{ fontSize: "12px" }}>
              {record?.auction_details?.lot}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Address",
      key: "8",
      dataIndex: "locaaddresstion",
      width: 100,
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              fontSize: "12px",
              fontFamily: "DMSans-Regular",
            }}
          >
            {record?.address},
            <br />
            {`Phone No: ${record?.phoneNo},`}
            <br />
            {`Email: ${record?.email}`}
          </div>
        );
      },
    },
    {
      title: "End Date",
      key: "9",
      width: 100,

      render: (text: any, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {moment(record?.auction_details?.enddate).format(
              "YYYY-MM-DD/ HH:MM"
            )}
          </div>
        );
      },
    },
    {
      title: "Activity",
      key: "10",
      render: (text: any, record: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Tooltip placement="bottom" title={"send Notifiactions"}>
              <BsSend
                size={25}
                onClick={() => props?.notificationModal(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ marginTop: "10px", maxHeight: "70vh", overflow: "scroll" }}>
      {contextHolder}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.meta?.page}
          total={props?.meta?.total_count}
          pageSize={props?.meta?.take}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
