import { Row, Col } from "react-bootstrap";
import { Button, Modal } from "antd";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slice/userSlice";

export default function LOgoutModel(props: any) {
  const LogOUT = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = () => {
      navigate(`/`);
      dispatch(logout());
    };
    return (
      <main>
        <Row>
          <div className="LogoutHeader">Log Out</div>
          <div className="LogoutSubHeader">
            Are you sure You want to log out
          </div>
          <Col md={6}>
            <Button
              style={{ width: "100%", height: "40px" }}
              onClick={props.close}
            >
              cancel
            </Button>
          </Col>
          <Col md={6}>
            <Button
              type="primary"
              style={{ width: "100%", height: "40px" }}
              onClick={logOut}
            >
              log out
            </Button>
          </Col>
        </Row>
      </main>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      width={400}
      footer={false}
      centered
    >
      <LogOUT />
    </Modal>
  );
}
