import { Button, message } from "antd";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineStar } from "react-icons/md";
import "../styles.scss";
import { FaHeart } from "react-icons/fa";
import { DELETE, GET, POST } from "../../../utils/apiCalls";
import { API } from "../../../config/api";
import { useNavigate } from "react-router-dom";
import empty from "../../../assets/images/empty.gif";

function FaveCard(props: any) {
  const navigate = useNavigate();
  const [deletedIds, setDeletedIds] = useState<any>([]);

  const RemoveFromFav = async (val: any) => {
    try {
      let url = API.DELETE_FAVOURITE + `/${val}`;
      let response: any = await DELETE(url);
      if (response) {
        message.success("Successfully Removed");
        setDeletedIds([...deletedIds, val]);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const filteredData = props?.data?.filter(
    (item: any) => !deletedIds.includes(item.id)
  );

  return (
    <div>
      <Row>
        {filteredData?.length ? (
          filteredData?.map((item: any) => (
            <Col md={4} key={item.id}>
              <div className="FavoriteCard ">
                <img
                  src={item?.auction_details?.images[0]}
                  alt="Auction item"
                  className="FavoriteCard_Image"
                />
                <div className="FavoriteCard_Content">
                  <div className="FavoriteCard_Title">
                    {item?.auction_details?.name}
                  </div>
                  <div className="FavoriteCard_Price">
                    Current Price: ${item?.auction_details?.price}
                  </div>
                  <div className="FavoriteCard_Description">
                    {item?.auction_details?.description}
                  </div>
                  <div className="FavoriteCard_remove">
                    <div
                      className="FavoriteCard_Button"
                      onClick={() =>
                        navigate(`/details/${item?.auction_details.id}`)
                      }
                    >
                      Place Bid
                    </div>
                    <b
                      onClick={() => RemoveFromFav(item.id)}
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      REMOVE
                    </b>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <>
            <div className="EmptyBoxFav">
              <img src={empty} />
            </div>
          </>
        )}
      </Row>
    </div>
  );
}

export default FaveCard;
