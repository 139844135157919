import { LiaUserSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import profile from "../../assets/images/beauty.jpeg";
import DynamicIcon from "./dynamicIcon";
import Menu from "./menu.json";
import "./styles.scss";
import { Card, Tag } from "antd";
import { Col, Row } from "react-bootstrap";
import { TbArticleFilledFilled } from "react-icons/tb";
import { BsFillCreditCardFill } from "react-icons/bs";

const NaveBar = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const User = useSelector((state: any) => state.User.user);

  return (
    <div className="sideBarUser">
      <Card className="profile_Box1">
        <Row>
          <Col>
            <img src={profile} alt="profile" className="profile_Box3" />
            <div className="profile_Box6">{User?.name}</div>
            <div className="profile_Box7">{User?.email}</div>
            <br />
            <div style={{ display: "flex", gap: "5px" }}>
              <Tag className="profileTags">Bids :12</Tag>
              <Tag className="profileTags">Wallet :$123</Tag>
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="profile_Box2" onClick={() => navigate(`/usr/myBids`)}>
        <div className="profile_Box2MyBIds">
          <TbArticleFilledFilled size={25} />
          <h6>My Bids</h6>
        </div>
      </Card>
      <Card
        className="profile_Box2CArd2"
        onClick={() => navigate(`/usr/transactions`)}
      >
        <div className="profile_Box2MyBIds">
          <BsFillCreditCardFill size={25} />
          <h6>Transaction</h6>
        </div>
      </Card>
    </div>
  );
};

export default NaveBar;
