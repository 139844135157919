import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header";
import "./styles.scss";
import FogotPassword from "./components/forgotpassword";

const ChangPassword = () => {
  return (
    <div className="password_Box1">
      <Container>
        <Header />
        <FogotPassword />
      </Container>
    </div>
  );
};

export default ChangPassword;
