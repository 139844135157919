import React from "react";
import { LuUserCircle } from "react-icons/lu";
import { FiUser } from "react-icons/fi";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";


const PopoverItem = () => {
    const User = useSelector((state: any) => state.User.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogouts = () => {
        dispatch(logout());
        navigate("/");
    };
    return (
        <div className="Profile-popover">
            <div className="Popover-item1">
                <LuUserCircle size={28} />
                <div className="Popover-txt1">{User.name}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <br />
                <Button onClick={handleLogouts}>log out </Button>
            </div>
        </div>
    );
};

export default PopoverItem;
