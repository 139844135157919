import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import TransactionHistory from "./components/transactionHistory";
import "./styles.scss";

const Transactions = () => {
  const Navigate = useNavigate();
  const User = useSelector((state: any) => state.User.user);
  const [Wallet, setWallet] = useState<any>();

  useEffect(() => {
    loadWallet();
  }, []);

  const loadWallet = async () => {
    let url = API.GET_BY_USERWALLET;
    let obj = {
      id: Number(User.id),
    };

    try {
      const response: any = await POST(url, obj);
      if (response) {
        setWallet(response);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="Transactions-Box1">
        {/* <PageHeader /> */}
        <Row>
          <Col md={12}>
            <div className="Transactions_Box2">
              {!Wallet?.data?.length ? (
                <>
                  <div className="Transactions_Box3">
                    Lets start the journey
                  </div>
                  <div className="Transactions_Box4">Create Your Wallet</div>
                  <div
                    className="Transactions_Box5"
                    onClick={() =>
                      Navigate("/Wallet", {
                        state: {
                          title: "Add money to  wallet",
                          mode: "initialDeposit",
                        },
                      })
                    }
                  >
                    Add money to wallet
                  </div>
                </>
              ) : (
                <>
                  <div className="Transactions_Box3">My Balance</div>
                  <div className="Transactions_Box4">
                    <LiaRupeeSignSolid size={25} />
                    {Wallet?.data?.length
                      ? `${Wallet?.data?.[0]?.wallet_Amount}`
                      : "0.00"}
                  </div>
                  <div
                    className="Transactions_Box5"
                    onClick={() =>
                      Navigate("/Wallet", {
                        state: {
                          title: "Add money to  wallet",
                          mode: "Deposit",
                          id: Wallet?.data?.[0]?.id,
                          amount: Wallet?.data?.[0]?.wallet_Amount,
                        },
                      })
                    }
                  >
                    Add money to wallet
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col md={12}>
            <TransactionHistory />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Transactions;
