import React from "react";
import Banner from "./Banner/banner";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Aution from "./Aucion/auction";
import { Container } from "react-bootstrap";
import "./style.scss";
import FirstSection from "./FirstSection/firstSection";
import Reviews from "./Reviews";
import BidBYCategory from "./ByCategory";
import About from "./about";
import Progress from "./progress";

const Home = () => {
  return (
    <>
      <div className="HomeBox1">
        <Container>
          <Header />
          <FirstSection />
          <br />
        </Container>
      </div>
      <Aution />
      <Reviews />
      <BidBYCategory />
      <Progress />
      <About />
      <Footer />
    </>
  );
};
export default Home;
