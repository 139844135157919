import { Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import empty from "../../../assets/images/empty.gif";
import { API } from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import "../styles.scss";
import { useNavigate } from "react-router-dom";

function ReadNotifications() {
  const Navigate = useNavigate();
  const [notify, setNotify] = useState<any>();
  const [page, setPage] = useState<any>(1);
  const [take, setTake] = useState<any>(10);

  const User = useSelector((state: any) => state.User.user);

  useEffect(() => {
    getNotification(page, take);
  }, []);

  const getNotification = async (page: any, take: any) => {
    let url = API.READED_NOTIFICATIONS;
    let obj = {
      UserId: Number(User?.id),
      page: page,
      take: take,
    };
    let res: any = await POST(url, obj);
    if (res?.status) {
      setNotify(res?.data);
    }
  };
  return (
    <div
      className={
        notify?.length ? "ReadNotifications_Box2" : "ReadNotifications_Box1"
      }
    >
      {notify?.length ? (
        notify.map((item: any, index: any) => {
          return (
            <Row
              className="ReadNotifications_Box3"
              onClick={() => Navigate(`/usr/Notification/${item?.id}`)}
            >
              <Col md={3}>
                <div className="ReadNotifications_Box4">
                  <img src={item?.aucton_details?.images[0]} />
                </div>
              </Col>
              <Col md={18}>
                <div className="ReadNotifications_Box5">{item.Heading}</div>
                <div className="ReadNotifications_Box6">{item.Body}</div>
              </Col>
              <Col md={3} className="ReadNotifications_Box7">
                {moment(item?.createdAt).format("DD MMM")}
              </Col>
            </Row>
          );
        })
      ) : (
        <img src={empty} />
      )}
    </div>
  );
}

export default ReadNotifications;
