import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";

function ActionsListing(props: any) {
  return (
    <div>
      <div className="DashboardBox6">
        Auctions Latest
        <span className="DashboardBox6_date">
          {moment().format("MMMM  YYYY")}{" "}
        </span>
      </div>
      <Row className="DashboardBox6_Head">
        <Col md={5}>
          <div className="DashboardBox6_Head1">Name of Auction</div>
        </Col>
        <Col md={2}>
          <div className="DashboardBox6_Head1">Category</div>
        </Col>
        <Col md={3}>
          <div className="DashboardBox6_Head1">Location</div>
        </Col>
        <Col md={2}>
          <div className="DashboardBox6_Head1">Price</div>
        </Col>
      </Row>
      {props?.data?.map((item: any) => {
        return (
          <>
            <Row style={{ width: "90%", cursor: "pointer" }}>
              <Col md={2}>
                <div className="DashboardBox7">
                  {item?.images ? (
                    <img src={item?.images[0]} />
                  ) : (
                    <div className="DashboardBox7_noImage">no media</div>
                  )}
                </div>
              </Col>
              <Col md={3}>
                <div className="DashboardBox8">
                  <div>{item?.name}</div>
                  <div className="DashboardBox8_date">
                    {moment(item?.createdAt).format("MMM DD, YYYY [at] HH:mm")}
                  </div>
                </div>
              </Col>
              <Col md={2}>
                <div className="DashboardBox8">{item?.category?.category}</div>
              </Col>
              <Col md={3}>
                <div className="DashboardBox8">{item?.location}</div>
              </Col>
              <Col md={2}>
                <div className="DashboardBox8">{`+$${item?.price}`}</div>
              </Col>
            </Row>
            <br />
          </>
        );
      })}
    </div>
  );
}

export default ActionsListing;
