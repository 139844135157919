import React from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import star from "../../../assets/images/5star.png";

function Reviews() {
  const Reviews = [
    {
      review:
        "I recently started using Auction for my buying and selling needs, and I couldn't be more pleased with the experience. The platform's wallet type purchasing system is incredibly convenient and secure, making transactions smooth and worry-free.",
      auther: "Emily Davis",
    },
    {
      review:
        "Bidding on items is generally smooth, but I have encountered occasional glitches where my bids didn't register correctly. This can be frustrating during competitive auctions. Additionally, the auction countdown timer could be more accurate; I've noticed slight discrepancies in the remaining time displayed.",
      auther: " David Bennett",
    },
  ];
  return (
    <div className="Review_Container">
      <Container fluid={false}>
        <div className="Review_Box1">Costumers Reviews</div>
        <Row>
          {Reviews?.map((item: any) => {
            return (
              <>
                <Col md={6} xs={12}>
                  <img className="Review_IMG" src={star} />
                  <div className="Review_Box2">{item.review}</div>
                  <br />
                  <div className="Review_Box3">{item.auther}</div>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Reviews;
