import React from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";

function Progress() {
  const rating = [
    {
      count: "10K+",
      title: "Satisfied Customers",
    },
    {
      count: "98%",
      title: "Positive Feedback",
    },
    {
      count: "$1M+",
      title: "Auctions Sold",
    },
  ];
  return (
    <div className="ProgressContaine">
      <Container>
        <Row>
          <Col md={12} xs={0}>
            <div className="Progress_Box6">
              <div className="Progress_Box1">Growing Fast</div>
              <div className="Progress_Box5">
                {rating.map((item: any) => (
                  <div className="Progress_Box2">
                    <div className="Progress_Box3">{item.count}</div>
                    <div className="Progress_Box4">{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
}

export default Progress;
