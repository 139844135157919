import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import "./styles.scss";

import DeleteAccount from "../deleteAccount";
import EditProfile from "../editProfile";
import Favourites from "../favourites";
import MyBids from "../myBids";
import Details from "../myBids/details";
import Overview from "../overview";
import Profile from "../profile";
import Transactions from "../transactions";
import NaveBar from "./navbar";
import NotificationList from "../Notification";
import DetailedNotification from "../NotificationDetails";
import UserHeader from "./userHeader";

const UserRoutes = () => {
  const { Sider, Content, Header } = Layout;
  return (
    <Layout className="Layout">
      <Header style={{ backgroundColor: "transparent" }} className="HeaderUser">
        <UserHeader />
      </Header>
      <Layout style={{ backgroundColor: "transparent" }}>
        <Sider
          width="30%"
          style={{ backgroundColor: "transparent" }}
          className="Sider"
        >
          <NaveBar />
        </Sider>
        <Content
          style={{
            textAlign: "center",
            minHeight: 120,
            backgroundColor: "transparent",
            overflowY: "scroll",
          }}
        >
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route path="/myBids" element={<MyBids />} />
            <Route path="/details" element={<Details />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/editProfile" element={<EditProfile />} />
            <Route path="/deleteAccount" element={<DeleteAccount />} />
            <Route path="/Notification" element={<NotificationList />} />
            <Route
              path="/Notification/:id"
              element={<DetailedNotification />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserRoutes;
