import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { API } from "../../config/api";
import { GET } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import FaveCard from "./components/faveCard";
import "./styles.scss";

const Favourites = () => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const User = useSelector((state: any) => state.User.user);

  useEffect(() => {
    LIstFavourites();
  }, []);

  const LIstFavourites = async () => {
    let url = API.USER_FAVOURITES + `/${User.id}`;

    try {
      let response: any = await GET(url, null);
      if (response) {
        setData(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="FavBox">
      {/* <PageHeader /> */}
      <Container fluid={false}>
        <div className="FavBox1">
          <div className="SecondCardmain-Txt1Fav">
            My Favourites &nbsp;
            <div className="SecondCardmain-Txt2Fav"></div>
          </div>
          {isLoading ? <Skeleton active /> : <FaveCard data={data} />}
        </div>
      </Container>
    </div>
  );
};

export default Favourites;
