import React from "react";
import {
  Card,
  Space,
  Table,
  Tag,
  Popconfirm,
  Pagination,
  Tooltip,
  Button,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { IoTrashOutline } from "react-icons/io5";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

function DataTable(props: any) {
  const navigate = useNavigate();
  const [Notifications, contextHolder] = notification.useNotification();

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "image",
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (text, record: any) => {
        return (
          <>
            {record.images ? (
              <img className="AuctionImage" src={record.images[0]} />
            ) : (
              <div className="AuctionImageNull">no media</div>
            )}
          </>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: 150,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {record?.name}
          </div>
        );
      },
    },
    {
      title: "Discription",
      dataIndex: "discription",
      key: "discription",
      width: 150,
      render: (text: any, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {record?.description}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
      render: (text: any, record: any) => {
        return (
          <Space size="middle">
            <Tag style={{ fontSize: "12px" }}>{`$${record?.price}`}</Tag>
          </Space>
        );
      },
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      render: (text: any, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {record?.location}
          </div>
        );
      },
    },
    {
      title: "End Date",
      key: "date",
      render: (text: any, record: any) => {
        return (
          <div style={{ fontSize: "12px", fontFamily: "DMSans-Regular" }}>
            {moment.utc(record?.enddate).format("YYYY-MM-DD/hh:mm")}
          </div>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => navigate(`/Auth/auction/Form/${record?.id}`)}
              />
            </Space>
            &nbsp;
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => props.onDelete(record.id)}
            >
              <IoTrashOutline color="red" size={20} cursor={"pointer"} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ marginTop: "10px", maxHeight: "70vh", overflow: "scroll" }}>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.meta?.page}
          total={props?.meta?.total_count}
          pageSize={props?.meta?.take}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
