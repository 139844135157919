import React, { useEffect, useState } from "react";
import "../styles.scss";
import { LuClock4 } from "react-icons/lu";

function CountDown(props: any) {
  const [countDownArray, setCountDownArray] = useState<any>();

  useEffect(() => {
    const timer = setInterval(() => {
      let timeObj: any = {};

      if (props?.data) {
        timeObj = calculateTimeRemaining(props?.data?.enddate);

        setCountDownArray(timeObj);
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  const calculateTimeRemaining = (endDate: any) => {
    const difference = new Date(endDate).getTime() - new Date().getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };
  return (
    <div className="CountDown_Box1">
      <div className="CountDown_Box2">
        <LuClock4 size={20} color="#fff" />
      </div>
      <div className="CountDown_Box3">
        <div className="CountDown_Box4">{countDownArray?.days}d</div>
      </div>
      <div style={{ border: "1px solid #fff", height: "50%" }}></div>
      <div className="CountDown_Box3">
        <div className="CountDown_Box4">{countDownArray?.hours} h</div>
      </div>
      <div style={{ border: "1px solid #fff", height: "50%" }}></div>

      <div className="CountDown_Box3">
        <div className="CountDown_Box4">{countDownArray?.minutes} m</div>
      </div>
      <div style={{ border: "1px solid #fff", height: "50%" }}></div>

      <div className="CountDown_Box3">
        <div className="CountDown_Box4">{countDownArray?.seconds} s</div>
      </div>
    </div>
  );
}

export default CountDown;
