import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/images/Headerlogo.png";
import "./styles.scss";
import Menu from "./menu.json";
import { useLocation, useNavigate } from "react-router-dom";
import PopoverItem from "./popoverItem";
import { Popover } from "antd";
import profile from "../../assets/images/beauty.jpeg";
import Bell from "../../assets/images/notifytrue.png";
import options from "../../assets/data/menu.json";
import { useSelector } from "react-redux";
import handsvg from "../../assets/images/waving-hand.svg";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import CustomDrawer from "../../components/Drower/drawer";

function UserHeader() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  let location = useLocation();
  const User = useSelector((state: any) => state.User.user);

  return (
    <div>
      <Row
        style={{
          borderBottom: ".5px solid gray",
          height: "60px",
        }}
      >
        <Col md={2} xs={3} className="userHeader">
          <img
            src={logo}
            alt="Header logo here"
            className="userLogo"
            onClick={() => navigate(`/`)}
          />
          <div className="naveMenusMOb">
            <div className="naveMenusMOb1">
              <HiOutlineMenuAlt3 size={25} onClick={() => setIsOpen(!isOpen)} />
            </div>
          </div>
        </Col>
        <Col md={6} xs={6} style={{ height: "100%" }}>
          <div className="naveMenus">
            <div className="naveMenus_Box1">
              {Menu?.slice(0, 10).map((item: any) => {
                return (
                  <div
                    key={item?.id}
                    onClick={() => navigate(item?.navigate)}
                    className={
                      location.pathname === item?.navigate
                        ? "naveBarUser-item active"
                        : "naveBarUser-item"
                    }
                  >
                    <div>{item?.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <img src={logo} alt="Header logo here" className="userLogoMob" />
        </Col>
        <Col md={4} xs={3} style={{ height: "100%" }}>
          <div className="profileBox">
            <div className="searchBoxUser">
              <img
                src={Bell}
                style={{ width: "20px", height: "20px" }}
                onClick={() => navigate(`/usr/Notification`)}
              />
            </div>
            <div className="searchBoxUserProfil">
              <Popover content={<PopoverItem />}>
                <div className="HeaderProfile-box2">
                  <img src={profile} />
                </div>
              </Popover>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="PrimaryCardBox1User">
          {`Hello, ${User.name}`}
          &nbsp;
          <img src={handsvg} />
        </div>
      </Row>
      <CustomDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        options={options}
      />
      {/* <PrimaryCard /> */}
    </div>
  );
}

export default UserHeader;
