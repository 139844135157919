import React from "react";
import PageHeader from "../routes/pageHeader";

const MyBidDetails = () => {
  return <div>
    < PageHeader second="details" secondPath="/usr/details" />

  </div>;
};

export default MyBidDetails;
