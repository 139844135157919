import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header";

function AboutUs() {
  return (
    <div className="AboutUsScreeen">
      <Container fluid={false}>
        <Header />
      </Container>
    </div>
  );
}

export default AboutUs;
