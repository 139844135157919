import { Button, Popover } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HiOutlineMenuAlt3, HiOutlineTag } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import options from "../../assets/data/menu.json";
import logo from "../../assets/images/Headerlogo.png";
import profile from "../../assets/images/beauty.jpeg";
import Bell from "../../assets/images/notifytrue.png";
import Bell1 from "../../assets/images/notifyfalse.png";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import CustomDrawer from "../Drower/drawer";
import ProfileItem from "./components/profileItems";
import "./styles.scss";
import { IoArrowForwardOutline } from "react-icons/io5";

const Header = () => {
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [ShowPopver, setShowPopver] = useState(false);
  const [showNotify, setShowNotify] = useState(false);
  const [emptyNotify, setEmptyNotify] = useState(false);
  const [page, setPage] = useState<any>(1);
  const [take, setTake] = useState<any>(3);
  const [notify, setNotify] = useState<any>();
  const navigation = useNavigate();
  const User = useSelector((state: any) => state.User.user);

  useEffect(() => {
    getNotification(page, take);
  }, []);

  const getNotification = async (page: any, take: any) => {
    let url = API.UNREADE_NOTIFICATIONS;

    let obj = {
      UserId: Number(User?.id),
      page: page,
      take: take,
    };
    let res: any = await POST(url, obj);
    if (res?.status) {
      setNotify(res?.data);
    }
  };
  const handleToggleClick = () => {
    setShowNotify((prev: any) => !prev);
  };
  const handleProfileClick = () => {
    setShowPopver((prev: any) => !prev);
  };
  return (
    <Container fluid={false}>
      <Row className="g-0 ">
        <Col lg={4} md={4} xs={3}>
          <div className="header-option-container">
            <div className="header-option-containerBox2">
              <div className="OptionsBar">
                {options?.slice(0, 3).map((item: any) => (
                  <li
                    key={item?.id}
                    className={
                      location.pathname === item?.path
                        ? "options active"
                        : "options"
                    }
                    onClick={() => navigation(item?.path)}
                  >
                    {item?.name}
                  </li>
                ))}
              </div>
            </div>
          </div>
          <div className="MOb_Menu">
            <div className="naveMenusMOb1Main">
              <HiOutlineMenuAlt3
                color="#000"
                size={25}
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        </Col>
        <Col lg={4} md={4} xs={6}>
          <div className="HeaderlogoBox">
            <img src={logo} />
          </div>
        </Col>
        <Col lg={4} md={4} xs={3}>
          {User?.id ? (
            <div className="searchBoxUserHomeBox1">
              <div className="searchBoxHome">
                {notify?.length ? (
                  <img
                    src={Bell}
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => handleToggleClick()}
                  />
                ) : (
                  <img
                    src={Bell1}
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => navigation(`/usr/Notification`)}
                  />
                )}
              </div>
              <div className="searchBoxHomeProfile">
                <div
                  className="HeaderProfile-box2Home"
                  onClick={() => handleProfileClick()}
                >
                  <img src={profile} />
                </div>
              </div>
            </div>
          ) : (
            <div className="AuthBox">
              <div
                className="AuthBoxLOgin"
                onClick={() => navigation(`/login`)}
              >
                Login
              </div>
              <div
                className="AuthBoxSignUp"
                onClick={() => navigation(`/signup`)}
              >
                Register
              </div>
            </div>
          )}
        </Col>
        <CustomDrawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          options={options}
        />
      </Row>
      {notify?.length && showNotify ? (
        <Row className="Notification" onMouseLeave={() => handleToggleClick()}>
          <Col>
            <div className="Notification_Box1">Notification</div>
            <hr />
            {notify?.slice(0, 3).map((item: any, index: any) => {
              return (
                <Row
                  style={{ marginBottom: "20px", cursor: "pointer" }}
                  onClick={() => navigation(`/usr/Notification/${item?.id}`)}
                >
                  <Col md={1}>
                    <div>
                      <HiOutlineTag />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="Notification_Box2">
                      <div className="Notification_Box3">{item?.Heading}</div>
                      <div className="Notification_Box4">{item?.Body}</div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="Notification_Box5">
                      {moment(item?.createdAt).format("DD MMM")}
                    </div>
                  </Col>
                </Row>
              );
            })}
            <div
              className="Notification_Box6"
              onClick={() => navigation(`/usr/Notification`)}
            >
              View all notification
              <IoArrowForwardOutline />
            </div>
          </Col>
        </Row>
      ) : null}
      {ShowPopver ? (
        <Row className="ProfileBox" onMouseLeave={() => handleProfileClick()}>
          <ProfileItem close={() => setShowPopver(false)} />
        </Row>
      ) : null}
    </Container>
  );
};

export default Header;
