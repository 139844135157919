import React from "react";
import { LuUserCircle } from "react-icons/lu";
import { FiUser } from "react-icons/fi";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { RiUser3Line } from "react-icons/ri";
import { AiOutlineHeart } from "react-icons/ai";
import { TbSquareForbid2 } from "react-icons/tb";
import { GrTransaction } from "react-icons/gr";
import { IoLogOutOutline } from "react-icons/io5";

const ProfileItem = (props: any) => {
  const User = useSelector((state: any) => state.User.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogouts = () => {
    dispatch(logout());
    navigate("/");
    props.close();
  };

  const Menus = [
    {
      head: "Profile",
      icons: <RiUser3Line />,
      path: "/usr",
    },
    {
      head: "Favourites",
      icons: <AiOutlineHeart />,
      path: "/usr/favourites",
    },
    {
      head: "My Bids",
      icons: <TbSquareForbid2 />,
      path: "/usr/myBids",
    },
    {
      head: "Transaction",
      icons: <GrTransaction />,
      path: "/usr/transactions",
    },
  ];
  return (
    <div className="Profile-popover">
      <div className="Popover-item1" style={{ marginBottom: "10px" }}>
        <LuUserCircle size={28} />
        <div className="Popover-txt1">{User.name}</div>
      </div>
      {Menus.map((item: any) => {
        return (
          <div className="Popover_Box1" onClick={() => navigate(item?.path)}>
            <div className="Popover_Box2">{item?.icons}</div>
            <div className="Popover_Box3"> {item?.head}</div>
          </div>
        );
      })}

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <div className="logoutsession" onClick={() => handleLogouts()}>
          <IoLogOutOutline size={20} />
          <div>log out</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileItem;
