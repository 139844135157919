import { message } from "antd";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import empty from "../../../assets/images/empty.gif";
import { API } from "../../../config/api";
import { DELETE } from "../../../utils/apiCalls";
import "../styles.scss";
import { useState } from "react";
import CancelBidModal from "./cancelModal";

function BidCard(props: any) {
  const navigate = useNavigate();
  const [item, setitem] = useState<any>();
  const [cancelBid, setCancelBid] = useState(false);

  const deleteBid = async (val: any) => {
    setitem(val);
    setCancelBid(true);
  };

  return (
    <>
      {props?.data?.length ? (
        props?.data?.map((item: any) => {
          return (
            <Col md={4}>
              <div className="BidCard">
                <img
                  className="BidCard__image"
                  src={item?.auction_details?.images[0]}
                  alt={item?.auction_details?.name}
                  onClick={() =>
                    navigate(`/details/${item?.auction_details.id}`)
                  }
                />
                <div className="BidCard__content">
                  <div className="BidCard__name">
                    {item?.auction_details?.name}
                  </div>
                  <div className="BidCard__description">
                    {item?.auction_details?.description}
                  </div>
                  <div className="BidCard__price">
                    <span>Your Price: &nbsp;${item?.bidPrice}</span>
                  </div>
                  <div className="BidCard__cancelBox">
                    <div className="payButton" onClick={() => deleteBid(item)}>
                      cancel Bid
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })
      ) : (
        <>
          <div className="EmptyBoxMybid">
            <img src={empty} />
          </div>
        </>
      )}
      {cancelBid ? (
        <CancelBidModal
          visible={cancelBid}
          data={item}
          close={() => setCancelBid(false)}
          refresh={() => props?.refresh()}
        />
      ) : null}
    </>
  );
}

export default BidCard;
