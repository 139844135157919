import { Card, Input, message } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Loadingbox from "../../components/loadingBox";
import { API } from "../../config/api";
import { DELETE, POST } from "../../utils/apiCalls";
import PageHeader from "../route/pageHeader";
import DataTable from "./components/dataTable";
import "./styles.scss";
import { RiRefund2Line } from "react-icons/ri";
import RefundModal from "../../components/refundModal";
import NotificationModal from "./components/notificationForm";

const Bids = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [isrefund, setIsRefund] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [data, setData] = useState<any>();
  const [selectedData, setselectedData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [meta, setMeta] = useState<any>();
  const [query, setQuery] = useState<any>();
  const [notification, setNotification] = useState<any>();
  const [ShowNOtificationModal, setShowNOtificationModal] = useState(false);

  useEffect(() => {
    loadData(page, take, "", "");
  }, []);

  const loadData = async (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.LIST_BIDS;

      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };
      let response: any = await POST(url, obj);
      if (response) {
        setData(response?.data);
        setMeta(response.data.meta);
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
      setIsloading(false);
    }
  };

  const deleteAuction = async (val: any) => {
    try {
      let url = API.DELETE_AUCTION + `/${val}`;
      let response: any = await DELETE(url);
      if (response) {
        message.success("successfully deleted");
        loadData(page, take, "", "");
      }
    } catch (error) {}
  };

  const onPageChange = (page: any, take: any) => {
    setPage(page);
    setTake(take);
    loadData(page, take, "", "");
  };

  const onSearch = (searchText: any) => {
    setQuery(searchText);
    setTimeout(() => {
      loadData(page, take, searchText, "");
    }, 500);
  };

  const Notification = (val: any) => {
    setNotification(val);
    setShowNOtificationModal(true);
  };

  return (
    <div>
      <PageHeader title={"Bids"} bredcume={"Bids"}>
        {isrefund ? (
          <div className="BidsBox1" onClick={() => setOpenRefund(true)}>
            <RiRefund2Line size={20} />
            refund{" "}
          </div>
        ) : null}

        <div>
          <Input
            placeholder="Search Bids By Lot number..."
            onChange={(e: any) => onSearch(e.target.value)}
            allowClear
            suffix={<CiSearch />}
            style={{ width: 300 }}
          />
        </div>
      </PageHeader>
      <Container>
        {isLoading ? (
          <Loadingbox />
        ) : (
          <Card>
            <DataTable
              meta={meta}
              data={data}
              selected={(val: any) => setselectedData(val)}
              allowRefund={() => setIsRefund(true)}
              noRefund={() => setIsRefund(false)}
              onDelete={(val: any) => deleteAuction(val)}
              onPageChange={(p: any, t: any) => onPageChange(p, t)}
              notificationModal={(val: any) => Notification(val)}
            />
          </Card>
        )}
      </Container>
      {openRefund ? (
        <RefundModal
          visible={openRefund}
          data={selectedData}
          close={() => setOpenRefund(false)}
          refresh={() => loadData(page, take, query, "")}
        />
      ) : null}
      {ShowNOtificationModal ? (
        <NotificationModal
          visible={ShowNOtificationModal}
          close={() => setShowNOtificationModal(false)}
          data={notification}
        />
      ) : null}
    </div>
  );
};

export default Bids;
