import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { BsArrowLeftCircle } from "react-icons/bs";

function PageHeader(props: any) {
  const { head, navigation, showCreateModal, setShowCreateModal } = props;

  const navigate = useNavigate();
  return (
    <div className="PageHeader">
      <div>
      <BsArrowLeftCircle
          size={30}
          color={"#C9EE45"}
          onClick={() => navigate(-1)}
        />
      </div>
      <div>
        <div className="PageHeader-txt1">{props?.title}</div>
        <div className="PageHeader-txt2">Admin / {props?.bredcume}</div>
      </div>
      <div style={{ flex: 1 }} />
      {props?.children}
    </div>
  );
}

export default PageHeader;
