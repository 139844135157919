import { Form, Select } from "antd";
import React from "react";
import country from "../countryCode.json";
function Prefixselector() {
  return (
    <>
      <Form.Item name="code" noStyle>
        <Select
          style={{
            width: 85,
            borderRadius: "0px",
          }}
          placeholder={"+971"}
          size="large"
          showSearch={true}
        >
          {country.map((item: any) => {
            return (
              <Select.Option key={item.dial_code} value={item.dial_code}>
                {item.dial_code}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
}

export default Prefixselector;
