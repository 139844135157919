import React, { useEffect, useState } from "react";
import Detail from "../../components/detailes";
import NaveBar from "../../users/routes/navbar";
import { API } from "../../config/api";
import { GET } from "../../utils/apiCalls";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import Header from "../../components/header";
import { Container } from "react-bootstrap";
import "./styles.scss";
import Footer from "../../components/footer";

const Auctiondetails = () => {
  const [data, setData] = useState<any>();
  const [bidCount, setBidCount] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(true);
  const { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    GetAuctions(id);
  }, []);

  const GetAuctions = async (id: any) => {
    try {
      const url = API.GET_A_AUCTION + `/${id}`;
      const response: any = await GET(url, null);
      if (response) {
        setData(response?.data);
        await BidCount(response?.data?.id);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const BidCount = async (id: any) => {
    try {
      const url = API.COUNTBID_BYITEM + `/${id}`;
      const response: any = await GET(url, null);
      if (response) {
        setBidCount(response);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="AuctiondetailsBox1">
        <Container fluid={false}>
          <Header />
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Detail
              data={data}
              count={bidCount}
              refresh={() => GetAuctions(id)}
            />
          )}
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Auctiondetails;
