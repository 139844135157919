import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import Charts from "./components/chart";
import { API } from "../../config/api";
import axios from "axios";
import ActionsListing from "./components/actionsListing";
import Cards from "./components/card";

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>();

  useEffect(() => {
    loadData(1, 2, "", "");
  }, []);

  const loadData = (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.BASE_URL + API.LIST_AUCTION;

      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };
      axios
        .post(url, obj)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("=error=", error);
    }
  };

  return (
    <div className="DashBoard_box">
      <div style={{ height: "25px" }}></div>
      <Container>
        <Row className="g-0">
          <Col md={4}>
            <div className="DashboardBox1">
              <Cards />
            </div>
          </Col>
          <Col md={8}>
            <div className="DashboardBox5">
              <div className="DashboardBox6">Auction Sales Flow</div>
              <Charts data={[]} />
            </div>
            <ActionsListing data={data} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
