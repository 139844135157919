import React from "react";
import { CircularProgress } from "@mui/material";

function Loadingbox() {
  return (
    <div className="LoadingBox">
      <CircularProgress style={{ color: "#83848f" }} />
      <br/>
      <div>Loading . . .</div>
    </div>
  );
}

export default Loadingbox;
