import React from "react";
import { TfiMoreAlt } from "react-icons/tfi";
import { Card } from "antd";
import { RiAuctionFill } from "react-icons/ri";
import "../styles.scss";
import { useNavigate } from "react-router-dom";

function Cards() {
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
        <Card className="DashboardBox1_Card">
          <div style={{ display: "flex" }}>
            <div className="DashboardBox1_Card_Box1">Total Earnings</div>
            &nbsp;
            <TfiMoreAlt color="#fff" />
          </div>
          <div className="DashboardBox1_Card_Box2">
            <strong className="DashboardBox1_Card_Box3">
              $23454<span className="DashboardBox1_Card_Box4">.25</span>
            </strong>
          </div>
          <div className="DashboardBox1_Card_Box5">
            <RiAuctionFill color="#fff" size={25} />
          </div>
        </Card>
        <div className="DashboardBox2">
          <div className="DashboardBox2_text1">
            <div>Total Views</div>
            <div>1234</div>
          </div>
          <div className="DashboardBox2_text1">
            <div>Product Sold</div>
            <div>4321</div>
          </div>
        </div>
      </div>
      <div className="DashboardBox3" onClick={() => navigate(`/Auth/auction`)}>
        Auction
      </div>
      <div className="DashboardBox4" onClick={() => navigate(`/Auth/Bids`)}>
        Bids
      </div>
    </div>
  );
}

export default Cards;
