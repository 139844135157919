import React from "react";
import { Tabs } from "antd";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import PageHeader from "../routes/pageHeader";
import "./styles.scss";
import ReadNotifications from "./components/readNotifications";
import UnReadNotifications from "./components/unReadNotification";
import { CiRead, CiUnread } from "react-icons/ci";

function NotificationList() {
  return (
    <div className="NotificationScreenBox">
      {/* <PageHeader /> */}
      <div className="NotificationScreen_box1">
        <Tabs
          defaultActiveKey="2"
          items={[
            {
              key: "1",
              label: "Read",
              children: <ReadNotifications />,
              icon: <CiRead />,
            },
            {
              key: "2",
              label: "Unread",
              children: <UnReadNotifications />,
              icon: <CiUnread />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default NotificationList;
