import React, { useEffect, useState } from "react";
import { API } from "../../../config/api";
import { useSelector } from "react-redux";
import { POST } from "../../../utils/apiCalls";
import { Skeleton } from "antd";
import "../styles.scss";
import { HiMiniCreditCard } from "react-icons/hi2";
import { Col, Row } from "react-bootstrap";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function TransactionHistory() {
  const User = useSelector((state: any) => state.User.user);
  const [page, setpage] = useState(1);
  const [take, settake] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState<any>();
  const [bidsData, setBidData] = useState<any>();

  useEffect(() => {
    loadHistory(page, take);
    GetMyBids();
  }, []);

  const loadHistory = async (page: any, take: any) => {
    try {
      let url = API.GET_BY_USERTRANSCTION;

      let obj = {
        id: Number(User.id),
        page,
        take,
      };

      let response: any = await POST(url, obj);
      if (response.status) {
        setData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("==error===", error);
    }
  };
  const GetMyBids = async () => {
    try {
      const url = API.GET_BIDBY_USER;
      let obj = {
        user: User.id,
      };
      const response: any = await POST(url, obj);
      if (response) {
        setBidData(response?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="TransactionHistory_box">
      <Row>
        <Col md={7}>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="TransactionHistory_box1">
                {data?.length ? (
                  <>
                    <div className="TransactionHistory_box2">History</div>
                    {data.map((item: any, index: any) => {
                      return (
                        <div
                          style={{
                            backgroundColor:
                              item?.mode === "withdraw"
                                ? " #FFE2E5"
                                : item?.mode === "initialDeposit"
                                ? "#E1F0FF"
                                : item?.mode === "Deposit"
                                ? "#C9F7F5"
                                : "#FFF4DE",
                            color:
                              item?.mode === "withdraw"
                                ? " #F64E60"
                                : item?.mode === "initialDeposit"
                                ? "#3197FF"
                                : item?.mode === "Deposit"
                                ? "#17C4BC"
                                : "#FFA800",
                            borderRadius: "10px",
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          <div className="TransactionHistory_box3">
                            <HiMiniCreditCard
                              color={
                                item?.mode === "withdraw"
                                  ? "#F64E60"
                                  : item?.mode === "initialDeposit"
                                  ? "#3197FF"
                                  : item?.mode === "Deposit"
                                  ? "#17C4BC"
                                  : "#FFA800"
                              }
                              size={15}
                            />
                            <div className="TransactionHistory_box5">
                              {item?.mode}
                            </div>
                            {item?.transaction_details?.failed_Bid &&
                            !showDetails ? (
                              <div
                                style={{
                                  display: "flex",
                                  textDecoration: "Underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowDetails(true)}
                              >
                                <div className="Detatilstransactio">
                                  Detatils
                                </div>
                                <IoIosArrowDown size={15} />
                              </div>
                            ) : item?.transaction_details?.failed_Bid &&
                              showDetails ? (
                              <div
                                style={{
                                  display: "flex",
                                  textDecoration: "Underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowDetails(false)}
                              >
                                <div className="Detatilstransactio">
                                  Detatils
                                </div>
                                <IoIosArrowUp size={15} />
                              </div>
                            ) : null}

                            <div className="TransactionHistory_box6">
                              <LiaRupeeSignSolid size={20} />
                              {item?.transaction_details?.amount}
                            </div>
                          </div>
                          {showDetails &&
                          item?.transaction_details?.failed_Bid ? (
                            <div className="TransactionHistory_box10">
                              <div className="TransactionHistory_box8">
                                <img
                                  src={
                                    item?.transaction_details?.failed_Bid
                                      .images[0]
                                  }
                                />
                                <div className="TransactionHistory_box9">
                                  <div className="TransactionHistory_box9txt1">
                                    {item?.transaction_details?.failed_Bid.name}
                                  </div>
                                  <div className="TransactionHistory_box9txt2">
                                    {
                                      item?.transaction_details?.failed_Bid
                                        .description
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </Col>
        <Col md={5}>
          <div className="TransactionHistory_box7">
            {isLoading ? (
              <Skeleton active />
            ) : (
              <>
                {bidsData?.length ? (
                  <>
                    <div className="TransactionHistory_box2">Spend for</div>
                    {bidsData?.map((item: any, index: any) => {
                      return (
                        <div className="TransactionHistory_box8">
                          <img src={item?.auction_details?.images[0]} />
                          <div className="TransactionHistory_box9">
                            <div className="TransactionHistory_box9txt1">
                              {item?.auction_details?.name}
                            </div>
                            <div className="TransactionHistory_box9txt2">
                              {item?.auction_details?.description}
                            </div>
                            <div className="TransactionHistory_box9txt3">
                              <span>Your Bid: &nbsp;${item?.bidPrice}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TransactionHistory;
