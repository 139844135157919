import React, { useEffect, useState } from "react";
import "../styles.scss";

function AuctionLoading() {
  const [compress, setCompress] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setCompress(false);
    }, 5000);
  }, []);

  return (
    <div className="loader">
      <svg
        className="spinner"
        width="40px"
        height="40px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
      {compress ? <div>compressing...</div> : <div>uploading...</div>}
    </div>
  );
}

export default AuctionLoading;
