import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles.scss";
import { Button, Form, Input, message } from "antd";
import { API } from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import { InputOTP } from "antd-input-otp";
import { EyeFilled, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function FogotPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [ShowOtp, setShowOtp] = useState(false);
  const [NewPassword, setNewPassword] = useState(false);
  const [updateData, setUpdateData] = useState<any>();

  const onFinish = async (val: any) => {
    try {
      let otp_string = val?.otp?.join("");
      let url = API.CHANGE_PASSWORD;
      let url2 = API.VERIFY_OTP;
      let url3 = API.UPDATE_PASSWORD;
      let reqObj = {
        email: val?.email,
      };
      let reqObj2 = {
        otp: otp_string,
        email: updateData?.email,
      };
      let reqObj3 = {
        email: updateData?.email,
        newpasword: val?.password,
      };
      let res: any = await POST(
        val?.password ? url3 : val?.otp ? url2 : url,
        val?.password ? reqObj3 : val?.otp ? reqObj2 : reqObj
      );
      if (res.status) {
        if (val?.otp) {
          setShowOtp(false);
          setNewPassword(true);
          message.success("OTP verified");
        } else if (val?.password) {
          message.success("password updation SucessFully");
          navigate("/login");
          setNewPassword(false);
        } else {
          setShowOtp(true);
          setUpdateData(res.data);
        }
      } else {
        if (val?.otp) {
          message.error("incorrect OTP");
        } else if (val?.password) {
          message.error("password updation failed try again");
        } else {
          message.error("incorrect Email please try with registered Email");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <div className="Password_Box1">FogotPassword</div>
          <br />
          <Form onFinish={onFinish} form={form}>
            {NewPassword ? (
              <>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter your New password",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter Your New Password"
                    bordered={false}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeFilled style={{ color: "#eec3ac" }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ color: "#eec3ac" }} />
                      )
                    }
                    style={{
                      borderBottom: "1px solid #3c211e",
                      borderRadius: "0px",
                      color: "#eec3ac",
                      width: "100%",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="ConfirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const password = getFieldValue("password");
                        if (!value || password === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(`please confirm your password`)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    bordered={false}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeFilled style={{ color: "#eec3ac" }} />
                      ) : (
                        <EyeInvisibleOutlined style={{ color: "#eec3ac" }} />
                      )
                    }
                    style={{
                      borderBottom: "1px solid #3c211e",
                      borderRadius: "0px",
                      color: "#eec3ac",
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </>
            ) : ShowOtp ? (
              <Form.Item name="otp">
                <InputOTP autoSubmit={form} inputType="numeric" />
              </Form.Item>
            ) : (
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Registered Email",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Your Registered Email"
                  bordered={false}
                  style={{
                    borderBottom: "1px solid #3c211e",
                    borderRadius: "0px",
                    color: "#eec3ac",
                    width: "100%",
                  }}
                />
              </Form.Item>
            )}
            <div className="Password_Box4">
              <Button htmlType="submit" className="Password_Box5">
                {NewPassword ? `Update` : ShowOtp ? `Verify OTP` : `Send OTP`}
              </Button>
            </div>
          </Form>
        </Col>
        <Col md={3}></Col>
      </Row>
    </Container>
  );
}

export default FogotPassword;
