import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles.scss";
import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { API } from "../../../config/api";
import { POST } from "../../../utils/apiCalls";

function Contact() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      let url = API.CREATE_ENQUIRY;
      let body = {
        name: val?.name,
        email: val?.email,
        message: val?.message,
      };

      let res: any = await POST(url, body);
      if (res?.status) {
        setIsLoading(false);
        message.success("Success full, we will contact you soon");
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <div className="d-none d-sm-block" style={{ height: "50px" }}></div>
          <div className="Contact_Box1">Contact Us</div>
          <div className="Contact_Box2">Start your Journey With Us Today</div>
          <br />
          <Form onFinish={onFinish} form={form}>
            <div className="Contact_Box3">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Full Name",
                  },
                ]}
              >
                <Input
                  placeholder="Full Name"
                  bordered={false}
                  style={{
                    borderBottom: "1px solid #3c211e",
                    borderRadius: "0px",
                    color: "#eec3ac",
                    width: "250px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  bordered={false}
                  style={{
                    borderBottom: "1px solid #3c211e",
                    borderRadius: "0px",
                    color: "#eec3ac",
                    width: "250px",
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item name="message">
              <TextArea
                placeholder="Message"
                bordered={false}
                style={{
                  borderBottom: "1px solid #3c211e",
                  borderRadius: "0px",
                  color: "#eec3ac",
                }}
              />
            </Form.Item>
            <div className="Contact_Box4">
              <Button
                htmlType="submit"
                className="Contact_Box5"
                loading={isLoading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>
        <Col md={3}></Col>
      </Row>
    </Container>
  );
}

export default Contact;
