import { Button, Form, Input, InputNumber } from "antd";
import React, { useState } from "react";
import "../styles.scss";
import TextArea from "antd/es/input/TextArea";
import { API } from "../../../config/api";
import { PUT } from "../../../utils/apiCalls";
import { useDispatch } from "react-redux";
import { update } from "../../../redux/slice/userSlice";
import Prefixselector from "../../../assets/data/prefixSelector/prefixselector";

function EditUser(props: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);

  const onFinish = async (val: any) => {
    setIsloading(true);
    try {
      const url = API.UPDATE_PROFILE + `/${props?.userId}`;
      const obj = val;
      const obj2 = { phoneNo: val?.code + val?.phoneNo };
      const response: any = await PUT(url, val?.phoneNo ? obj2 : obj);
      if (response) {
        dispatch(update(response.data));
        setIsloading(false);
      }
    } catch (error) {
      setIsloading(false);
      console.log("=error=", error);
    }
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        name: props.data,
        email: props.data,
        address: props.data,
      }}
    >
      <div className="SecondCard_Box1">
        <Form.Item name={props.fieldname} style={{ width: "40%" }}>
          {props.id === 4 ? (
            <TextArea className="SecondCard_Box2" placeholder={props?.label} />
          ) : props.id === 5 ? (
            <Input
              addonBefore={<Prefixselector />}
              size="large"
              placeholder="Enter new Number"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
            />
          ) : (
            <Input className="SecondCard_Box2" placeholder={props?.label} />
          )}
        </Form.Item>
        <Button
          loading={isLoading}
          className="SecondCard_Box3"
          htmlType="submit"
        >
          Update
        </Button>
      </div>
    </Form>
  );
}

export default EditUser;
