import { Button, Card, Input } from "antd";
import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaRegAddressCard } from "react-icons/fa";
import { IoPhonePortraitSharp } from "react-icons/io5";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { TbFlag2 } from "react-icons/tb";
import { TfiEmail } from "react-icons/tfi";
import { useSelector } from "react-redux";
import EditUser from "./editUser";

function SecondCard() {
  const User = useSelector((state: any) => state.User.user);
  const [editMode, setEditMode] = useState<any>({});

  const details = [
    {
      id: 1,
      head: "User Name",
      label: User?.name,
      buttontext: "Change Name",
      labelicon: <MdDriveFileRenameOutline />,
      fieldname: "name",
    },
    {
      id: 2,
      head: "Email",
      label: User?.email,
      buttontext: "Change Email",
      labelicon: <TfiEmail />,
      fieldname: "email",
    },
    {
      id: 3,
      head: "Nationality",
      label: User?.nationality,
      labelicon: <TbFlag2 />,
      fieldname: "name",
    },
    {
      id: 4,
      head: "Address",
      label: User?.address,
      buttontext: "Change Address",
      labelicon: <FaRegAddressCard />,
      fieldname: "address",
    },
    {
      id: 5,
      head: "Phone Number",
      label: User?.phoneNo,
      buttontext: "Change PhoneNo",
      labelicon: <IoPhonePortraitSharp />,
      fieldname: "phoneNo",
    },
  ];

  const handleEditClick = (itemId: any) => {
    setEditMode((prev: any) => ({ ...prev, [itemId]: !prev[itemId] }));
  };
  return (
    <div className="SecondCardmain">
      <div className="SecondCardmain-Txt1">
        About &nbsp;
        <div className="SecondCardmain-Txt2"></div>
      </div>
      {details.map((item: any, index: any) => {
        return (
          <>
            <Card
              key={index}
              className="SecondCard"
              style={{
                backgroundColor: index % 2 === 0 ? "#FBF7F3" : "#E1D8CE",
              }}
            >
              <div className="SecondCard_Box">
                {item.labelicon}&nbsp;&nbsp;{item.head}
              </div>
              <hr />
              <div className="LabelUser">{item.head}</div>
              <div className="LabelUser1">
                <div className="LabelUser2">{item.label}</div>
                {item.buttontext ? (
                  <div className="Profilebuttontext">
                    <Button
                      onClick={() => handleEditClick(item.id)}
                      style={{
                        backgroundColor: "rgb(0 0 0 / 5%) ",
                        border: "none",
                      }}
                    >
                      <CiEdit />
                      &nbsp;&nbsp;
                      {item.buttontext}
                    </Button>
                  </div>
                ) : null}
              </div>
              <br />
              {editMode[item.id] ? (
                <EditUser
                  userId={User?.id}
                  id={item.id}
                  label={item.head}
                  data={item.label}
                  fieldname={item?.fieldname}
                />
              ) : null}
            </Card>
          </>
        );
      })}
    </div>
  );
}

export default SecondCard;
