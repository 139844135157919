const ThemeObj = {
  token: {
    colorPrimary: "#402a1c",
    fontFamily: "DMSans-Medium",
  },
  components: {
    Button: {
      colorPrimary: "black",
      colorDefault: "#402a1c",
      color: "#402a1c",
      c9ee45: "DMSans-Bold",
      colorPrimaryHover: "",
      colorPrimaryActive: "",
    },
  },
};

export default ThemeObj;
