import React from "react";
import PageHeader from "../../components/pageHeader";

const Settings = () => {
  return (
    <div className="screen-box">
      <div className="main-box">
        <PageHeader header={`Settings`} />
      </div>
    </div>
  );
};

export default Settings;
