import { useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import { Drawer } from "antd";
import logo from "../../assets/images/Headerlogo.png";
import profile from "../../assets/images/beauty.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { IoLogOutOutline } from "react-icons/io5";
import { logout } from "../../redux/slice/userSlice";
interface Props {
  open: boolean;
  onClose: () => void;
  options?: {}[];
  scrollToContactUs?: () => void;
}

const CustomDrawer = ({ open, onClose, options, scrollToContactUs }: Props) => {
  const User = useSelector((state: any) => state.User.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const title = <div className="drawer-btn-container"></div>;
  const footer = <></>;

  const handleLogouts = () => {
    dispatch(logout());
    navigate("/");
    onClose();
  };
  return (
    <>
      <Drawer
        title={title}
        width={250}
        onClose={onClose}
        placement={"left"}
        open={open}
        closable={false}
        footer={footer}
        style={{ backgroundColor: "#FFFAED", borderRadius: "0px" }}
      >
        {User?.id ? (
          <div>
            <img className="drawerProfile" src={profile} />
            <div className="drawerProfileName">{User?.name}</div>
            <div style={{ color: "gray" }}>{User?.email}</div>
          </div>
        ) : (
          <img style={{ width: "50%", height: "" }} src={logo} />
        )}

        <ul className="drawer-options-container">
          {options
            ?.slice(User.id ? 3 : 0, User.id ? options?.length : 3)
            .map((item: any) => {
              return (
                <li key={item?.id}>
                  <div
                    className="drawer-options"
                    onClick={() => {
                      navigation(item?.path);
                      onClose();
                    }}
                  >
                    {item?.name}
                  </div>
                </li>
              );
            })}
        </ul>
        <br />
        {User?.id ? (
          <div className="drawerProfilLogout" onClick={handleLogouts}>
            <IoLogOutOutline color="#000" size={25} />
            &nbsp;&nbsp;&nbsp; Log Out
          </div>
        ) : null}
      </Drawer>
    </>
  );
};

export default CustomDrawer;
