import profile from "../../assets/images/wi.png";
import {
  PiBellSimpleRingingThin,
  PiChatCenteredTextThin,
} from "react-icons/pi";

import PopoverItem from "./popoverItem";
import { Popover } from "antd";

function LayoutHeader(props: any) {
  return (
    <div className="LayoutHeader">
      <div className="LayoutHeader-txt1">Admin Panel</div>
      <div style={{ flex: 1 }} />
      <div className="LayoutHeader-box">
        <div className="LayoutHeader-box1">
          <PiBellSimpleRingingThin size={25} />
        </div>
        <div className="LayoutHeader-box1">
          <PiChatCenteredTextThin size={25} />
        </div>
        <Popover content={<PopoverItem />}>
          <div className="LayoutHeader-box1">
            <img src={profile} style={{ width: 25 }} />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default LayoutHeader;
