import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaSquareXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { SlPhone } from "react-icons/sl";
import { VscMail } from "react-icons/vsc";
import logo from "../../assets/images/Headerlogo.png";
import "./styles.scss";
import { useEffect, useState } from "react";
import { API } from "../../config/api";
import { GET } from "../../utils/apiCalls";

const Footer = () => {
  const socialMediaIcons = [
    { icon: <FaFacebookF size={20} /> },
    { icon: <FaSquareXTwitter size={20} /> },
    { icon: <FaInstagram size={20} /> },
    { icon: <FaYoutube size={20} /> },
  ];
  const [category, setCategory] = useState<any>();
  useEffect(() => {
    GetCategories();
  }, []);

  const GetCategories = async () => {
    let url = API.GET_CATEGORIES;
    let response: any = await GET(url, null);
    if (response) {
      setCategory(response?.data);
    }
  };
  return (
    <div className="FooterBox">
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <div className="logo">
              <img src={logo} />
            </div>
            <ul className="FooterBoxtext">
              <li></li>
              <li>
                <a>
                  <VscMail size={20} />
                  &nbsp; auction@gmail.com
                </a>
              </li>
              <li>
                <a>
                  <SlPhone size={20} />
                  &nbsp; 9690617812866
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <div className="FooterBox4">Categories</div>
            <ul className="FooterBoxtext">
              {category?.map((item: any) => {
                return (
                  <li>
                    <a> {item?.category}</a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <div className="FooterBox4">Supports</div>
            <ul className="FooterBoxtext">
              <li>
                <a>Privacy Policy</a>
              </li>
              <li>
                <a>Terms & Condition</a>
              </li>
              <li>
                <a>Contact Us</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <div className="FooterBox4">NewsLetters</div>
            <ul className="FooterBoxtext">
              <li>
                <li>About Us</li>
              </li>
              <li>
                <a>Meet Our Team</a>
              </li>
              <li>
                <a>Our Responsibilities</a>
              </li>
            </ul>
          </Col>
        </Row>
        <hr style={{ color: "#9f5f2d" }} />
        <Row>
          <Col xs={12} md={6}>
            <div className="FooterBox1">
              {socialMediaIcons?.map((item: any) => {
                return <div className="FooterBox2">{item?.icon}</div>;
              })}
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="FooterBox3">
              <a>©Copyright all reserved</a>
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};
export default Footer;
