import React, { useEffect, useState } from "react";
import "./styles.scss";
import { POST } from "../../utils/apiCalls";
import { API } from "../../config/api";
import { useSelector } from "react-redux";
import BidCard from "./components/bidCard";
import { Skeleton } from "antd";
import { Container, Row } from "react-bootstrap";
import PageHeader from "../routes/pageHeader";

const MyBids = () => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  const User = useSelector((state: any) => state.User.user);

  useEffect(() => {
    GetMyBids();
  }, []);

  const GetMyBids = async () => {
    try {
      const url = API.GET_BIDBY_USER;

      let obj = {
        user: User.id,
      };
      const response: any = await POST(url, obj);
      if (response) {
        setData(response?.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  return (
    <div className="MyBids_box">
      {/* <PageHeader /> */}
      <Container fluid={false}>
        <div className="MyBids_box1">
          <div className="SecondCardmain-Txt1Bid">
            My Bids &nbsp;
            <div className="SecondCardmain-Txt2Bid"></div>
          </div>
          <Row>
            {isLoading ? (
              <Skeleton active />
            ) : (
              <BidCard data={data} getMyBids={() => GetMyBids()} />
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default MyBids;
