import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { POST } from "../../utils/apiCalls";
import PageHeader from "../routes/pageHeader";
import PrimaryCard from "./components/firstCard";
import SecondCard from "./components/secondCard";
import "./styles.scss";

const Overview = () => {
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState<any>();

  useEffect(() => {
    loadData(1, 1, "", "");
  }, []);

  const loadData = async (page: any, take: any, query: any, date: any) => {
    try {
      const url = API.LIST_AUCTION;

      let obj = {
        page: page,
        take: take,
        query: query,
        enddate: date,
      };

      let response: any = await POST(url, obj);
      if (response) {
        setData(response.data);
        setIsloading(false);
      } else {
        console.log("error");
        setIsloading(false);
      }
    } catch (error) {
      console.log("=error=", error);
      setIsloading(false);
    }
  };

  return (
    <>
      <div className="OverView_Home">
        {/* <PageHeader /> */}
        {/* <br /> */}
        <Row>
          {/* <Col md={12}>
            <PrimaryCard />
          </Col> */}
          <Col md={12}>
            <SecondCard />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Overview;
