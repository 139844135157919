import { Button, Card, Form, Input, message } from "antd";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss"
import { Col, Row } from "react-bootstrap";
import img from "../../assets/images/pexels-led-supermarket-577513.jpg"
import logo from '../../assets/images/logo.png';
const ChangePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (val: any) => {
    let obj = {
      newpasword: val?.password,
      userId: 1,
    };
    let url = `http://localhost:8020/user/UpdatePassword`;
    try {
      axios.post(url, obj).then((response) => {
        if (response.data.status === true) {
          message.success("update password successfully");
        } else {
          message.error("can't complete update password ");
        }
      });
    } catch {
      message.error("Oops,somthing went wrong");
    }
  };
  return (
    <div className="ChangePassword-Box1">
      <Row>
        <Col md={6}>
          <img className="ChangePassword-img1" src={img} />
        </Col>
        <Col md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="ChangePassword-Box2" >
            <img className="ChangePassword-img2" src={logo} />
            <h2>
              Forgot Password
            </h2>
            <p>
              kindeley enter the email address tied to your<br /> account we would help you reset your password
            </p>
            <Form form={form} onFinish={onFinish} >
              <label className="newsFormLabels">Enter email</label>
              <Form.Item name={"mail"}>
                <Input className="Profile-input"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Button htmlType="submit" type="primary" style={{ width: "100%" }} size="large">
                Recover Password
              </Button>

            </Form>

          </div>
        </Col>

      </Row>
      {/* <Card style={{ width: "50%" }}>
        <div>Create New Password</div>
        <br />
        <br />
        <Form form={form} onFinish={onFinish}>
          <label className="newsFormLabels">New Password</label>
          <Form.Item name={"password"}>
            <Input.Password />
          </Form.Item>
          <label className="newsFormLabels">Confirm Password</label>
          <Form.Item
            name={"confirm"}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const password = getFieldValue("password");
                  if (!value || password === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`please confirm your password`)
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Button htmlType="submit" type="default">
            submit
          </Button>
        </Form>
      </Card> */}
    </div>
  );
};

export default ChangePassword;
