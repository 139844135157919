import { Button, Form, InputNumber, Modal, message } from "antd";
import { InputOTP } from "antd-input-otp";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaTriangleExclamation, FaWhatsapp } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Verify from "../../../assets/images/passsssword.svg";
import "../styles.scss";
import { API } from "../../../config/api";
import { DELETE, POST } from "../../../utils/apiCalls";
import { CiCircleInfo } from "react-icons/ci";

export default function CancelBidModal(props: any) {
  const { data, userId } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [Mobverification, setMobVerification] = useState(true);
  const [wallet, setWallet] = useState<any>();
  const [otp, setOTP] = useState(false);
  const [otpData, setOtpData] = useState<any>();
  const [otpValue, setOtpValue] = useState<any>();

  const User = useSelector((state: any) => state.User.user);
  const [form] = Form.useForm();

  useEffect(() => {
    loadWallet();
  }, []);

  const loadWallet = async () => {
    let url = API.GET_BY_USERWALLET;
    let obj = {
      id: Number(User.id),
    };

    try {
      const response: any = await POST(url, obj);
      if (response?.status) {
        setWallet(response);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onFinish = async () => {
    try {
      let url = API.DELETE_BID + data?.id;
      let response: any = await DELETE(url);
      if (response.status) {
        message.success("successfully deleted");
        props?.refresh();
        props?.close();
        await UpdateWallet();
      }
    } catch (err) {
      console.log(err);
      message.error("Oops, somthing went wrong");
      setIsLoading(false);
    }
  };

  const onSendOTP = async () => {
    try {
      let url = API.REQUEST_OTP;
      let obj = {
        phoneNumber: `+${User.phoneNo}`,
      };
      let res: any = await POST(url, obj);
      if (res?.reqstatus) {
        setOTP(true);
        setOtpData(res);
        setOtpValue("");
      }
    } catch (error) {
      console.log("==error==", error);
    }
  };

  const otpConfirm = async (val: any) => {
    let stringOTP = val?.join("");
    try {
      let url = API.VERIFY_SMS_OTP;
      let obj = {
        token: otpData?.token,
        otp: stringOTP,
        phoneNumber: otpData?.data?.phoneNo,
        user_id: otpData?.data?.id,
      };
      const response: any = await POST(url, obj);
      if (response?.data?.verifystatus) {
        setMobVerification(false);
      }
    } catch (error) {}
  };

  const UpdateWallet = async () => {
    let obj = {
      amount: Number(data?.bidPrice),
      date: new Date().toISOString(),
      transactionId: "Refund(Cancel Bid)",
      type: "Refund(Cancel Bid)",
    };
    let body = {
      id: Number(wallet?.data?.[0]?.id),
      wallet_Amount:
        Number(wallet?.data?.[0]?.wallet_Amount) + Number(data?.bidPrice),
      payment_details: obj,
    };
    let url = API.UPDATE_WALLET;
    try {
      const response: any = await POST(url, body);
      if (response) {
        isertTransaction(response);
      }
    } catch (error) {
      console.log("=======Error=====", error);
    }
  };
  const isertTransaction = async (val: any) => {
    let body = {
      mode: val?.data?.type || val?.data?.payment_details?.type,
      wallet_ID: Number(val?.data?.id),
      user_ID: Number(val?.data?.user_ID),
      transaction_details: val?.data?.payment_details,
    };
    let url = API.CREATE_TRANSACTION;
    try {
      const response: any = await POST(url, body);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={props.visible}
      onCancel={() => {
        props.close();
      }}
      width={400}
      footer={false}
      centered
    >
      <Row>
        <Col md={12}>
          {Mobverification ? (
            <>
              <div className="BidModalBox1">
                <img src={Verify} />
                <div className="BidModalBox2">Verification Method</div>
                <div className="BidModalBox3">The code will be send to </div>
                <div className="BidModalBox4">{`+${User.phoneNo}`}</div>

                {otp ? (
                  <>
                    <br />
                    <InputOTP
                      value={otpValue}
                      onChange={setOtpValue}
                      autoSubmit={otpConfirm}
                      inputType="numeric"
                    />
                    <br />
                    <div className="forgot-div">
                      <b onClick={onSendOTP}>resend OTP</b>
                    </div>
                  </>
                ) : (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      className="BidModalBox5"
                      style={{ backgroundColor: "#57a721", color: "#fff" }}
                      onClick={onSendOTP}
                    >
                      <FaWhatsapp color="#fff" size={25} />
                      WhatsApp
                    </Button>

                    <div
                      id="recaptcha-container"
                      className="justify-center flex"
                    ></div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="cancelBidQuats">
                <div className="cancelBidQuatsIcon">
                  <FaTriangleExclamation color="#F64E60" size={30} />
                </div>
                <div className="cancelBidtittle">Cancel Bid </div>
                <div className="cancelBidDiscription">
                  Are you sure you want to cancel
                </div>
              </div>
              <div className="UpdatePassword-btn-Box">
                <Button
                  style={{
                    width: "50%",
                    height: "40px",
                    borderRadius: "10px",
                  }}
                  onClick={() => props.close()}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    width: "50%",
                    height: "40px",
                    borderRadius: "10px",
                  }}
                  onClick={onFinish}
                  type="primary"
                  loading={isLoading}
                >
                  Cancel Bid
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
}
