import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/slice/userSlice";
import { POST } from "../../utils/apiCalls";
import discription from "../../assets/images/loginbackground.png";
import "./styles.scss";
import { IoIosArrowRoundForward, IoLogoWhatsapp } from "react-icons/io";
import { CiMobile1 } from "react-icons/ci";
import Prefixselector from "../../assets/data/prefixSelector/prefixselector";
import { API } from "../../config/api";
import { InputOTP } from "antd-input-otp";
function Login() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [phoneLogin, setPhoneLogin] = useState(false);
  const [Showotp, setOTP] = useState(false);
  const [data, setData] = useState<any>();

  const onFinish = async (val: any) => {
    let stringOTP = val?.otp?.join("");
    let url = API.USER_LOGIN;
    let url2 = API.REQUEST_OTP;
    let url3 = API.VERIFY_SMS_OTP;
    let obj = {
      email: val?.email,
      password: val?.password,
    };
    let obj2 = {
      phoneNumber: val?.code + val?.phone,
    };
    let obj3 = {
      token: data?.token,
      otp: stringOTP,
      phoneNumber: data?.data?.phoneNo,
      user_id: data?.data?.id,
    };

    let api = val?.otp ? url3 : val?.phone ? url2 : url;
    let RequestObj = val?.otp ? obj3 : val?.phone ? obj2 : obj;
    try {
      setLoading(true);
      const response: any = await POST(api, RequestObj);
      if (response?.status) {
        dispatch(login(response.data));
        message.success("login success");
        if (response?.data?.role === "User") {
          navigate(`/`);
        } else if (response?.data?.role === "Admin") {
          navigate(`/Auth`);
        }
      } else if (response?.reqstatus) {
        setOTP(true);
        setPhoneLogin(false);
        setData(response);
      } else if (response?.data?.verifystatus) {
        setOTP(false);
        dispatch(login(response.data.data));
        message.success("Login Success");
        navigate(`/`);
      } else {
        message.error("login failed");
      }
    } catch (error) {
      console.log(error);
      message.error("login failed");
    } finally {
      setLoading(false);
    }
  };

  const ResendOTP = async () => {
    try {
      let url = API.REQUEST_OTP;

      let obj = {
        phoneNumber: `+${data?.data?.phoneNo}`,
      };
      const response: any = await POST(url, obj);
      if (response?.reqstatus) {
        setOTP(true);
        setPhoneLogin(false);
        setData(response);
        form.setFieldsValue({
          otp: "",
        });
      } else {
        form.setFieldsValue({
          otp: "",
        });
      }
    } catch (error) {
      console.log("=", error);
    }
  };
  return (
    <>
      <div className="logIn-Box1">
        <Container fluid={false}>
          <Row className="g-3">
            <Col md={6} xs={0}>
              <div className="log_Box2Ipad">
                <img className="log_Box3IMG" src={discription} />
              </div>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <div className="log_Box1">
                <Card className="log_Box3">
                  <div className="logIn-head">Log In</div>
                  <div className="forgot-div">
                    Don't have an account?{" "}
                    <a
                      style={{ color: "black" }}
                      onClick={() => navigate("/signup")}
                    >
                      <b>Create a new account</b>
                    </a>
                  </div>

                  <div className="">
                    <Form onFinish={onFinish} form={form}>
                      {Showotp ? (
                        <>
                          <br />
                          <Form.Item name="otp">
                            <InputOTP autoSubmit={form} inputType="numeric" />
                          </Form.Item>
                          <div className="forgot-div">
                            <b onClick={ResendOTP}>resend OTP</b>
                          </div>
                        </>
                      ) : phoneLogin ? (
                        <>
                          <div className="logInboard">
                            <div> We will send an OTP to your Whats App</div>
                            <IoLogoWhatsapp color={"green"} size={25} />
                          </div>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: " your phone number!",
                              },
                            ]}
                          >
                            <Input
                              addonBefore={<Prefixselector />}
                              size="large"
                              placeholder="Enter Phone Number"
                              type="text"
                              bordered={false}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              style={{
                                borderBottom: "1px solid #211915",
                                borderRadius: "0px",
                              }}
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <div className="logInboard"></div>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Email",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Email"
                              bordered={false}
                              style={{
                                borderBottom: "1px solid #3c211e",
                                borderRadius: "0px",
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Password",
                              },
                            ]}
                          >
                            <Input.Password
                              size="large"
                              placeholder="password"
                              style={{
                                borderBottom: "1px solid #3c211e",
                                borderRadius: "0px",
                              }}
                              bordered={false}
                              iconRender={(visible) =>
                                visible ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )
                              }
                            />
                          </Form.Item>
                          <div className="forgot-div">
                            <a
                              style={{ color: "black" }}
                              onClick={() => navigate(`/changePassword`)}
                            >
                              <b>Forgot Password..?</b>
                            </a>
                          </div>
                          <div className="or-div">
                            <div className="or-hr"></div>
                            <div
                              style={{
                                fontFamily: "DMSans-SemiBold",
                                fontSize: "12px",
                              }}
                            >
                              OR
                            </div>
                            <div className="or-hr"></div>
                          </div>
                          <div className="PhoneLogin">
                            <div
                              className="PhoneLoginBox1"
                              onClick={() => setPhoneLogin(true)}
                            >
                              <CiMobile1 color="#3c211e" size={25} />
                              <div
                                style={{
                                  fontFamily: "DMSans-Bold",
                                  fontSize: "12px",
                                }}
                              >
                                <b>Phone Login</b>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="ButtonDiv">
                        <div className="ButtonDiv3">
                          <div className="ButtonDiv1">
                            {phoneLogin ? `Get OTP` : `Log In`}
                          </div>
                          <Button
                            className="ButtonDiv2"
                            loading={loading}
                            htmlType="submit"
                          >
                            <IoIosArrowRoundForward size={25} color="#fff" />
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
